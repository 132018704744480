import MailchimpSubscribe from "react-mailchimp-subscribe";
import EmailAdressForm from "./EmailAdressForm";

const MailchimpForm = props => {

    const postUrl = `https://alluxi.us4.list-manage.com/subscribe/post-json?u=${process.env.REACT_APP_MAIL_CHIMP_U}&id=${process.env.REACT_APP_MAIL_CHIMP_ID}`;
    
    return (
        <div>
            <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                    <EmailAdressForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    );
};

export default MailchimpForm;