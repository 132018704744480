import awsCover from "../src/assets/img/technology/aws.jpeg";
import awsIcon from "../src/assets/img/services/tool-aws.png";
import djangoCover from "../src/assets/img/technology/django.jpeg";
import djangoIcon from "../src/assets/img/services/tool-django.png";
import ionicCover from "../src/assets/img/technology/ionic.jpeg";
import ionicIcon from "../src/assets/img/services/tool-ionic.png";
import javascriptCover from "../src/assets/img/technology/javascript.jpeg";
import javascriptIcon from "../src/assets/img/services/tool-js.png";
import postgreIcon from "../src/assets/img/services/tool-postgre.png";
import pythonCover from "../src/assets/img/technology/python.png";
import pythonIcon from "../src/assets/img/services/tool-python.png";
import reactCover from "../src/assets/img/technology/react.jpeg";
import reactIcon from "../src/assets/img/services/tool-react.png";
import reactNativeIcon from "../src/assets/img/services/tool-react.png";
import superPorteroProblems from "../src/assets/img/caseStudies/sp-problems.png";
import vueCover from "../src/assets/img/technology/vue.png";
import vueIcon from "../src/assets/img/services/tool-vue.png";

function shuffleArray(array) {
    return array.sort(() => Math.random() - 0.5);
}

export function useTecnologyData() {
    const tecnology = shuffleArray([
        {
            name: "React",
            slug: "react-software-development",
            main_icon: reactIcon,
            image_what_we_do: reactCover,
            title: "react-title",
            description: "react-description",
            service_title: "react-service-title",
            service_description: "react-service-description",
            services: [{
                    name: "react-service-name1",
                    description: "react-service-description1",
                },
                {
                    name: "react-service-name2",
                    description: "react-service-description2",
                },
                {
                    name: "react-service-name3",
                    description: "react-service-description3",
                },
            ],
            what_we_do_title: "react-what-we-do-title",
            what_we_do_description: "react-what-we-do-description",
        },
        {
            name: "Python",
            slug: "python-software-development",
            main_icon: pythonIcon,
            image_what_we_do: pythonCover,
            title: "python-title",
            description: "python-description",
            service_title: "python-service-title",
            service_description: "python-service-description",
            services: [{
                    name: "python-service-name1",
                    description: "python-service-description1",
                },
                {
                    name: "python-service-name2",
                    description: "python-service-description2",
                },
                {
                    name: "python-service-name3",
                    description: "python-service-description3",
                },
            ],
            what_we_do_title: "python-what-we-do-title",
            what_we_do_description: "python-what-we-do-description",
        },
        {
            name: "AWS",
            slug: "aws",
            main_icon: awsIcon,
            image_what_we_do: awsCover,
            title: "aws-title",
            description: "aws-description",
            service_title: "aws-service-title",
            service_description: "aws-service-description",
            services: [{
                    name: "aws-service-name1",
                    description: "aws-service-description1",
                },
                {
                    name: "aws-service-name2",
                    description: "aws-service-description2",
                },
                {
                    name: "aws-service-name3",
                    description: "aws-service-description3",
                },
            ],
            what_we_do_title: "aws-what-we-do-title",
            what_we_do_description: "aws-what-we-do-description",
        },
        {
            name: "Django",
            slug: "django",
            main_icon: djangoIcon,
            image_what_we_do: djangoCover,
            title: "django-title",
            description: "django-description",
            service_title: "django-service-title",
            service_description: "django-service-description",
            services: [{
                    name: "django-service-name1",
                    description: "django-service-description1",
                },
                {
                    name: "django-service-name2",
                    description: "django-service-description2",
                },
                {
                    name: "django-service-name3",
                    description: "django-service-description3",
                },
            ],
            what_we_do_title: "django-what-we-do-title",
            what_we_do_description: "django-what-we-do-description",
        },
        {
            name: "React Native",
            slug: "react-native",
            main_icon: reactNativeIcon,
            image_what_we_do: superPorteroProblems,
            title: "react-native-title",
            description: "react-native-description",
            service_title: "react-native-service-title",
            service_description: "react-native-service-description",
            services: [{
                    name: "react-native-service-name1",
                    description: "react-native-service-description1",
                },
                {
                    name: "react-native-service-name2",
                    description: "react-native-service-description2",
                },
                {
                    name: "react-native-service-name3",
                    description: "react-native-service-description3",
                },
            ],
            what_we_do_title: "react-native-what-we-do-title",
            what_we_do_description: "react-native-what-we-do-description",
        },
        {
            name: "PostgreSQL",
            slug: "postgresql",
            main_icon: postgreIcon,
            image_what_we_do: superPorteroProblems,
            title: "postgres-title",
            description: "postgres-description",
            service_title: "postgres-service-title",
            service_description: "postgres-service-description",
            services: [{
                    name: "postgres-service-name1",
                    description: "postgres-service-description1",
                },
                {
                    name: "postgres-service-name2",
                    description: "postgres-service-description2",
                },
                {
                    name: "postgres-service-name3",
                    description: "postgres-service-description3",
                },
            ],
            what_we_do_title: "postgres-what-we-do-title",
            what_we_do_description: "postgres-what-we-do-description",
        },
        {
            name: "JavaScript",
            slug: "javascript-software-development",
            main_icon: javascriptIcon,
            image_what_we_do: javascriptCover,
            title: "js-title",
            description: "js-description",
            service_title: "js-service-title",
            service_description: "js-service-description",
            services: [{
                    name: "js-service-name1",
                    description: "js-service-description1",
                },
                {
                    name: "js-service-name2",
                    description: "js-service-description2",
                },
                {
                    name: "js-service-name3",
                    description: "js-service-description3",
                },
            ],
            what_we_do_title: "js-what-we-do-title",
            what_we_do_description: "js-what-we-do-description",
        },
        {
            name: "Ionic",
            slug: "ionic-software-development",
            main_icon: ionicIcon,
            image_what_we_do: ionicCover,
            title: "ionic-title",
            description: "ionic-description",
            service_title: "ionic-service-title",
            service_description: "ionic-service-description",
            services: [{
                    name: "ionic-service-name1",
                    description: "ionic-service-description1",
                },
                {
                    name: "ionic-service-name2",
                    description: "ionic-service-description2",
                },
                {
                    name: "ionic-service-name3",
                    description: "ionic-service-description3",
                },
            ],
            what_we_do_title: "ionic-what-we-do-title",
            what_we_do_description: "ionic-what-we-do-description",
        },
        {
            name: "Vue",
            slug: "vue-software-development",
            main_icon: vueIcon,
            image_what_we_do: vueCover,
            title: "vue-title",
            description: "vue-description",
            service_title: "vue-service-title",
            service_description: "vue-service-description",
            services: [
                {
                    name: "vue-service-name1",
                    description: "vue-service-description1",
                },
                {
                    name: "vue-service-name2",
                    description: "vue-service-description2",
                },
                {
                    name: "vue-service-name3",
                    description: "vue-service-description3",
                },
            ],
            what_we_do_title: "vue-what-we-do-title",
            what_we_do_description: "vue-what-we-do-description",
        },
    ]);

    return tecnology;
}