import { CheckIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";

export default function AboutUsLanding({ noClick }) {
  const { t } = useTranslation();

  return (
    <div className="pt-16">
      <div className="relative pb-16 m-auto bg-white">
        <div className="mx-4 xl:mx-16 md:mx-10 ">
          <div className="xl:mx-auto xl:max-w-7xl xl:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="lg:col-start-1">
              <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                {t("about")}
              </span>
              <h1 className="text-5xl text-nblue-900 font-bold">
                {t("about-us-title")}
              </h1>
              <p className="font-light mt-4 text-lg text-agray-500 m-auto">
                {t("about-us-desc")}
              </p>
              <p className="font-light mt-8  text-lg text-agray-500  m-auto ">
                {t("about-question")}
              </p>
              <div className="grid grid-cols-2 gap-2">
                <div className="cols-span-1">
                  <div className="flex">
                    <div className="w-5">
                      <CheckIcon className="mt-5 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                    </div>
                    <p className="font-light mt-4 ml-2 text-lg text-agray-500 md:w-1/2 xl:w-full">
                      {t("b-ideas")}
                    </p>
                  </div>
                  <div className="flex">
                    <div className="w-5">
                      <CheckIcon className="mt-5 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                    </div>
                    <p className="font-light mt-4 ml-2 text-lg text-agray-500 md:w-1/2 xl:w-full">
                      {t("b-flexibility")}
                    </p>
                  </div>
                </div>
                <div className="cols-span-1">
                  <div className="flex">
                    <div className="w-5">
                      <CheckIcon className="mt-5 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                    </div>
                    <p className="font-light mt-4 ml-2 text-lg text-agray-500 md:w-1/2 xl:w-full">
                      {t("b-cost")}
                    </p>
                  </div>
                  <div className="flex">
                    <div className="w-5">
                      <CheckIcon className="mt-5 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                    </div>
                    <p className="font-light mt-4 ml-2 text-lg text-agray-500 md:w-1/2 xl:w-full">
                      {t("b-communication")}
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div className="flex col-span-1">
                  <div className="w-5">
                    <CheckIcon className="mt-5 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                  </div>
                  <p className="font-light mt-4 ml-2 text-lg text-agray-500 md:w-1/2 xl:w-full">
                    {t("b-innovative")}
                  </p>
                </div>
                <div className="flex col-span-1">
                  <div className="w-5">
                    <CheckIcon className="mt-5 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                  </div>
                  <p className="font-light mt-4 ml-2 text-lg text-agray-500 md:w-1/2 xl:w-full">
                    {t("b-satisfaction")}
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2 place-self-center">
              <img
                className="w-full"
                src={require("../assets/img/home/how-prototype.png")}
                alt="prototype"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
