import DesignDevIcon from "../src/assets/img/industries/ic-ind.png";
import LaunchIcon from "../src/assets/img/industries/ic-launch.png";
import SupportIcon from "../src/assets/img/industries/ic-support.png";
import education from "../src/assets/img/industries/education.png";
import educationDesc from "../src/assets/img/industries/educationdesc.png";
import educationValue from "../src/assets/img/industries/education-value.png";
import finance from "../src/assets/img/industries/finance.png";
import financeDesc from "../src/assets/img/industries/financedesc.png";
import financeValue from "../src/assets/img/industries/finance-value.png";
import healthcare from "../src/assets/img/industries/healthcare.png";
import healthcareDesc from "../src/assets/img/industries/healthcaredesc.png";
import healthcareValue from "../src/assets/img/industries/healthcare-value.png";
import { useTranslation } from "react-i18next";

function shuffleArray(array) {
  return array.sort(() => Math.random() - 0.5);
}

export function useIndustriesData() {
  const { t } = useTranslation();

  const industry = shuffleArray([
    {
      name: "health-care",
      slug: "healthcare",
      main_icon: healthcare,
      main_text: "health-care-desc",
      desc_title: "health-care-desc_title",
      desc_subtitle: "health-care-desc_subtitle",
      desc_img: healthcareDesc,
      desc_text: "health-care-desc_text",
      help_title: "health-care-help-title",
      help_desc: "health-care-help-desc",
      help: [
        {
          icon: DesignDevIcon,
          title: "health-care-design-title",
          desc: "health-care-design-desc",
        },
        {
          icon: LaunchIcon,
          title: "health-care-launch-title",
          desc: "health-care-launch-desc",
        },
        {
          icon: SupportIcon,
          title: "health-care-support-title",
          desc: "health-care-support-desc",
        },
      ],
      support_title: "health-care-benefit-title",
      support_desc: "health-care-benefit-desc",
      support_benefits: [
        {
          desc: "health-care-benefit1",
        },
        {
          desc: "health-care-benefit2",
        },
        {
          desc: "health-care-benefit3",
        },
        {
          desc: "health-care-benefit4",
        },
        {
          desc: "health-care-benefit5",
        },
        {
          desc: "health-care-benefit6",
        },
      ],
      value_title: "Zplendid",
      value_desc: t("zplendid-challenge"),
      value_img: healthcareValue,
      value_slug: "zplendid",
    },
    {
      name: "finance",
      slug: "finance",
      main_icon: finance,
      main_text: "finance-desc",
      desc_title: "finance-desc_title",
      desc_subtitle: "finance-desc_subtitle",
      desc_img: financeDesc,
      desc_text: "finance-desc_text",
      help_title: "finance-help-title",
      help_desc: "finance-help-desc",
      help: [
        {
          icon: DesignDevIcon,
          title: "finance-design-title",
          desc: "finance-design-desc",
        },
        {
          icon: LaunchIcon,
          title: "finance-launch-title",
          desc: "finance-launch-desc",
        },
        {
          icon: SupportIcon,
          title: "finance-support-title",
          desc: "finance-support-desc",
        },
      ],
      support_title: "finance-benefit-title",
      support_desc: "finance-benefit-desc",
      support_benefits: [
        {
          desc: "finance-benefit1",
        },
        {
          desc: "finance-benefit2",
        },
        {
          desc: "finance-benefit3",
        },
        {
          desc: "finance-benefit4",
        },
        {
          desc: "finance-benefit5",
        },
        {
          desc: "finance-benefit6",
        },
      ],

      value_title: "Digitab",
      value_desc: t("digitab-outcome"),
      value_img: financeValue,
      value_slug: "digitab",
    },
    {
      name: "education",
      slug: "education",
      main_icon: education,
      main_text: "education-desc",
      desc_title: "education-desc_title",
      desc_subtitle: "education-desc_subtitle",
      desc_img: educationDesc,
      desc_text: "education-desc_text",
      help_title: "education-help-title",
      help_desc: "education-help-desc",
      help: [
        {
          icon: DesignDevIcon,
          title: "education-design-title",
          desc: "education-design-desc",
        },
        {
          icon: LaunchIcon,
          title: "education-launch-title",
          desc: "education-launch-desc",
        },
        {
          icon: SupportIcon,
          title: "education-support-title",
          desc: "education-support-desc",
        },
      ],
      support_title: "education-benefit-title",
      support_desc: "education-benefit-desc",
      support_benefits: [
        {
          desc: "education-benefit1",
        },
        {
          desc: "education-benefit2",
        },
        {
          desc: "education-benefit3",
        },
        {
          desc: "education-benefit4",
        },
        {
          desc: "education-benefit5",
        },
        {
          desc: "education-benefit6",
        },
      ],
      value_title: "Howamigoing?",
      value_desc: t("haig-challenge"),
      value_img: educationValue,
      value_slug: "howamigoing",
    },
  ]);

  return industry;
}
