import { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { CheckIcon } from "@heroicons/react/outline";
import ContactUs from "./ContactUs";
import FAQs from "./FAQs";
import { Helmet } from "react-helmet";
import Loading from "../components/shared/Loading";
import Ready from "./Ready";
import WeHaveItAll2 from "./WeHaveItAll2";
import WhyChooseUs from "./WhyChooseUs";
import { useIndustriesData } from "../useIndustriesData";
import { useTranslation } from "react-i18next";

export default function Industry() {
  let { slug } = useParams();

  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");

  const industry = useIndustriesData();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qParam = searchParams.get("q");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [slug]);

  useEffect(() => {
    try {
      const filterSlug = industry.filter((item) => item.slug === slug);

      if (filterSlug.length === 0) {
        navigate("page-not-found");
      }

      setData(filterSlug[0]);
    } catch (error) {
      console.log(error);
    }
  }, [slug, industry, navigate]);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  return (
    <Fragment>
      {!loading ? (
        <Fragment>
          <Helmet>
            <title>{t(data.name)} | Alluxi</title>
            <link rel="canonical" href={window.location.href} />
            <meta name="description" content={t(data.desc_title)} />
            <meta property="og:title" content={t(data.name)} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={data.main_icon} />
            <meta property="og:description" content={t(data.desc_title)} />
            <meta property="og:url" content={window.location.href} />
          </Helmet>
          <div
            className={`relative pt-14 md:pt-36 xl:pt-28 m-auto ${
              qParam === "landing" && "pb-32"
            }`}
          >
            {/* MAIN */}
            <main className="mx-4 lg:mx-40 tv:mx-96 px-4 lg:px-0 my-8 pt-16">
              <div className="w-[120px] h-[127px] lg:w-[114px] lg:h-28 m-auto">
                <img
                  className="pb-6 rounded-2xl"
                  src={data.main_icon}
                  alt="cover"
                />
              </div>
              <div className="text-center">
                <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                  {t("industries")}
                </span>
                <h1 className="text-5xl text-nblue-900 font-bold">
                  {t(data.name)}
                </h1>
                <p className="font-light mt-4 text-lg text-agray-500 m-auto mb-[204px] lg:mb-[232px]">
                  {t(data.main_text)}
                </p>
              </div>
            </main>
            {/* DESCRIPTION */}
            <div className="bg-white mb-12 tv:mx-96 mx-4 md:mx-40">
              <div className="">
                <div className="relative overflow-hidden">
                  <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-6">
                    <div className="md:order-2">
                      <div className="mt-6">
                        <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                          {t(data.desc_title)}
                        </span>
                        <h2 className="text-5xl text-nblue-900 font-bold">
                          {t(data.desc_subtitle)}
                        </h2>
                        <p className="font-light mt-4 text-lg text-agray-500 m-auto">
                          {t(data.desc_text)}
                        </p>
                      </div>
                    </div>
                    <img
                      className="mt-10 md:mt-0 w-[358px] h-[404px] md:h-[553px] md:w-[448px] rounded-[28px]"
                      src={data.desc_img}
                      alt="what-we-do-img"
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* HOW CAN WE HELP */}
            <div className="px-4 lg:px-40 tv:px-96 px-4 lg:px-0 pt-16 pt-16 bg-agray-100 pb-[112px]">
              <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                {t("how-can-we-help")}
              </span>
              <h2 className="text-5xl text-nblue-900 font-bold">
                {t(data.help_title)}
              </h2>
              <p className="font-light mt-4 text-lg text-agray-500 m-auto">
                {t(data.help_desc)}
              </p>
              <div className="relative">
                <div className="mt-10 mx-auto grid grid-cols-1 md:grid-cols-3 lg:gap-8">
                  {data.help.map((item, index) => (
                    <div className="py-6 w-full" key={index}>
                      <div className="h-12 w-12">
                        <img
                          className="w-fit h-full "
                          src={item.icon}
                          alt="icon Passion"
                        />
                      </div>
                      <div className="mt-6">
                        <h3 className="text-2xl text-nblue-900 font-bold">
                          {t(item.title)}
                        </h3>
                        <p className="font-light mt-4  text-lg text-agray-500">
                          {t(item.desc)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* SUPPORT */}
            <div className="bg-white mb-12 mt-[80px] px-4 lg:px-40 tv:px-96">
              <div className="">
                <div className="relative overflow-hidden">
                  <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                    {t("support")}
                  </span>
                  <h2 className="text-5xl text-nblue-900 font-bold">
                    {t(data.support_title)}
                  </h2>
                  <div className="mt-4 md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-[96px]">
                    <div className="col-span-1">
                      <div className="">
                        <p className="font-light text-lg text-agray-500 m-auto">
                          {t(data.support_desc)}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-1 m-auto mt-4">
                      {data.support_benefits.map((item, index) => (
                        <div className="flex my-8 h-32">
                          <div className="w-5">
                            <CheckIcon className="mt-1 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                          </div>
                          <div
                            className="font-light text-lg text-agray-500 pl-2"
                            dangerouslySetInnerHTML={{
                              __html: t(item.desc),
                            }}
                          ></div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* VALUE */}

            <div className="md:max-w-3xl lg:max-w-5xl xl:max-w-7xl tv:max-w-none tv:mx-[336px] mx-auto">
            <Link
              to={
                currentLanguage === "en"
                  ? `/our-work/detail/${data.value_slug}`
                  : `/es/our-work/detail/${data.value_slug}`
              }
            >
              <div className="mt-8 pt-16 bg-agray-100 mb-[112px] px-4 md:px-16 pb-[164px] md:rounded-[42px] sm:w-full xs:w-full sm:px-4 xs:px-4">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div className="col-span-1">
                    <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                      {t("value")}
                    </span>
                    <h2 className="text-5xl text-nblue-900 font-bold">
                      {data.value_title}
                    </h2>
                    <p className="font-light mt-4 text-lg text-agray-500 m-auto">
                      {t(data.value_desc)}
                    </p>
                  </div>
                  <div className="w-full h-[409px] m-auto my-8 md:mt-0 md:w-[488px] md:h-[512px] col-span-1 md:ml-[64px]">
                    <img src={data.value_img} alt="industry_image"></img>
                  </div>
                </div>
              </div>
            </Link>
            </div>
            {qParam === "landing" && <FAQs></FAQs>}
            <WeHaveItAll2 />
          </div>
          {qParam === "landing" && <WhyChooseUs></WhyChooseUs>}
          {qParam === "landing" ? <ContactUs></ContactUs> : <Ready></Ready>}
        </Fragment>
      ) : (
        <div className="relative pt-14 md:pt-36 xl:pt-28 m-auto">
          <Loading />
        </div>
      )}
    </Fragment>
  );
}
