import { Fragment, useCallback, useEffect, useState } from 'react';

import { CheckIcon } from '@heroicons/react/outline';
import { Helmet } from 'react-helmet';
import Ready from './Ready';
import { useTranslation } from 'react-i18next';

export default function Careers(props) {
  const { t, i18n } = useTranslation();

  const [operationJobs, setOperationJobs] = useState([]);
  const [programmingJobs, setProgrammingJobs] = useState([]);
  const [designJobs, setDesignJobs] = useState([]);
  const [salesJobs, setSalesJobs] = useState([]);

  const [loading, setLoading] = useState(true);

  const fetchCareerData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_GETONBRD}?api_key=${process.env.REACT_APP_GETONBRD_KEY}`
      );
      if (!response.ok) {
        throw new Error('Something went wrong');
      }
      const data = await response.json();

      const publishedCareers = data.data.filter(
        (career) => career.attributes.state === 'published'
      );

      const operationJobs = publishedCareers.filter(
        (career) => career.attributes.category_name === 'Operaciones / Admin'
      );

      const programmingJobs = publishedCareers.filter(
        (career) => career.attributes.category_name === 'Programación'
      );

      const designJobs = publishedCareers.filter(
        (career) => career.attributes.category_name === 'Diseño / UX'
      );

      const salesJobs = publishedCareers.filter(
        (career) => career.attributes.category_name === 'Comercial y Ventas'
      );

      setOperationJobs(operationJobs);
      setProgrammingJobs(programmingJobs);
      setDesignJobs(designJobs);
      setSalesJobs(salesJobs);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  useEffect(() => {
    fetchCareerData();
  }, [fetchCareerData]);

  const hasOpenRoles = salesJobs.length > 0 || operationJobs.length > 0 || programmingJobs.length > 0 || designJobs.length > 0;

  return (
    <>
      <Helmet>
        <title>{t('careers')} | Alluxi </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t('careers-desc')} />
        <meta property="description" content={t('careers-desc')} />
        <meta property="og:title" content={t('careers')} />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={require('../assets/img/careers/work.png')}
        />
        <meta property="og:description" content={t('careers-desc')} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      {loading ? <div>Loading...</div> : null}

      <div className="relative pt-44 m-auto bg-white px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <div className="m-auto top-0 text-center">
          <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
            {t('careers')}
          </span>
          <h1 className="text-4xl xl:text-5xl text-nblue-900 font-bold">
            {t('careers-title')}
          </h1>
          <p className="font-light mt-4 text-center text-lg text-agray-500 m-auto ">
            {t('career-subtitle')}
          </p>
        </div>
        <div className="mt-24">
          <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-8 items-center">
            <div className="">
              <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                {t('benefits')}
              </span>
              <h2 className="text-5xl text-nblue-900 font-bold">
                {t('work-with-us')}
              </h2>
              <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                {t('careers-desc')}
              </p>
              <div className="flex">
                <CheckIcon className="mt-5 float-bottom bg-agray-500 text-white w-5 h-5 rounded-full"></CheckIcon>
                <p className="font-light mt-4  text-lg text-agray-500 pl-2">
                  {t('remote-first')}
                </p>
              </div>
              <div className="flex">
                <CheckIcon className="mt-5 float-bottom bg-agray-500 text-white w-5 h-5 rounded-full"></CheckIcon>
                <p className="font-light mt-4  text-lg text-agray-500 pl-2">
                  {t('flexible-time')}
                </p>
              </div>
              <div className="flex">
                <CheckIcon className="mt-5 float-bottom bg-agray-500 text-white w-5 h-5 rounded-full"></CheckIcon>
                <p className="font-light mt-4  text-lg text-agray-500 pl-2">
                  {t('health-care')}
                </p>
              </div>
              <div className="flex">
                <CheckIcon className="mt-5 float-bottom bg-agray-500 text-white w-5 h-5 rounded-full"></CheckIcon>
                <p className="font-light mt-4  text-lg text-agray-500 pl-2">
                  {t('self-improvement')}
                </p>
              </div>
              <div className="flex">
                <CheckIcon className="mt-5 float-bottom bg-agray-500 text-white w-5 h-5 rounded-full"></CheckIcon>
                <p className="font-light mt-4  text-lg text-agray-500 pl-2">
                  {t('vacation-days')}
                </p>
              </div>
            </div>
            <img
              className="w-88 max-h-96 m-auto my-8 md:my-0"
              src={require('../assets/img/careers/work.png')}
              alt="careers"
            />
          </div>
        </div>
        <div className="relative pt-44 md:pb-16 m-auto bg-white">
          <div className="m-auto top-0 w-full text-center">
            <h2 className="text-5xl text-nblue-900 font-bold">
              {t('open-roles')}
            </h2>
          </div>
          <div className="relative mt-10">
            {!loading && !hasOpenRoles ? (
              <div className="text-center text-xl text-agray-500">
                {t('no-open-roles')}
              </div>
            ) : (
              <div className="xl:mx-32 md:mx-16">
                {salesJobs.length > 0 && (
                  <Fragment>
                    <div className="pb-6">
                      <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                        {t('sales')}
                      </span>
                    </div>
                    {salesJobs.map((job, index) => (
                      <div className="xl:flex md:flex pb-6" key={job.id}>
                        <a
                          href={`https://www.getonbrd.com/jobs/sales/${job.id}`}
                          className="no-underline hover:text-ablue-600 focus:text-ablue-500 text-2xl xl:text-3xl text-nblue-900 font-bold"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {job.attributes.title}
                        </a>

                        {job.attributes.remote === true ? (
                          <h2 className="xl:absolute md:absolute xl:right-32   md:right-16 text-xl text-agray-500 flex">
                            {t('remote')}
                          </h2>
                        ) : (
                          <h2 className="xl:absolute md:absolute xl:right-32   md:right-16 text-xl text-agray-500 flex">
                            {job.attributes.countries.map((country, index) => (
                              <Fragment key={index}>
                                <p> {country} </p> &nbsp;
                              </Fragment>
                            ))}
                          </h2>
                        )}
                      </div>
                    ))}
                  </Fragment>
                )}
                {operationJobs.length > 0 && (
                  <Fragment>
                    <div className="pb-6">
                      <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                        {t('management')}
                      </span>
                    </div>
                    {operationJobs.map((job, index) => (
                      <div className="xl:flex md:flex pb-6" key={job.id}>
                        <a
                          href={`https://www.getonbrd.com/jobs/operaciones-management/${job.id}`}
                          className="no-underline hover:text-ablue-600 focus:text-ablue-500 text-2xl xl:text-3xl text-nblue-900 font-bold"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {job.attributes.title}
                        </a>

                        {job.attributes.remote === true ? (
                          <h2 className="xl:absolute md:absolute xl:right-32   md:right-16 text-xl text-agray-500 flex">
                            {t('remote')}
                          </h2>
                        ) : (
                          <h2 className="xl:absolute md:absolute xl:right-32   md:right-16 text-xl text-agray-500 flex">
                            {job.attributes.countries.map((country, index) => (
                              <Fragment key={index}>
                                <p> {country} </p> &nbsp;
                              </Fragment>
                            ))}
                          </h2>
                        )}
                      </div>
                    ))}
                  </Fragment>
                )}
                {programmingJobs.length > 0 && (
                  <Fragment>
                    <div className="pb-6">
                      <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                        {t('programming')}
                      </span>
                    </div>
                    {programmingJobs.map((job, index) => (
                      <div className="xl:flex md:flex pb-6" key={job.id}>
                        <a
                          href={`https://www.getonbrd.com/jobs/programming/${job.id}`}
                          className="no-underline hover:text-ablue-600 focus:text-ablue-500 text-2xl xl:text-3xl text-nblue-900 font-bold"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {job.attributes.title}
                        </a>

                        {job.attributes.remote === true ? (
                          <h2 className="xl:absolute md:absolute xl:right-32   md:right-16 text-xl text-agray-500 flex">
                            {t('remote')}
                          </h2>
                        ) : (
                          <h2 className="xl:absolute md:absolute xl:right-32   md:right-16 text-xl text-agray-500 flex">
                            {job.attributes.countries.map((country, index) => (
                              <Fragment key={index}>
                                <p> {country} </p> &nbsp;
                              </Fragment>
                            ))}
                          </h2>
                        )}
                      </div>
                    ))}
                  </Fragment>
                )}
                {designJobs.length > 0 && (
                  <Fragment>
                    <div className="pb-6">
                      <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                        {t('design')}
                      </span>
                    </div>
                    {designJobs.map((job, index) => (
                      <div className="xl:flex md:flex pb-6" key={job.id}>
                        <a
                          href={`https://www.getonbrd.com/jobs/design-ux/${job.id}`}
                          className="no-underline hover:text-ablue-600 focus:text-ablue-500 text-2xl xl:text-3xl text-nblue-900 font-bold"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {job.attributes.title}
                        </a>

                        {job.attributes.remote === true ? (
                          <h2 className="xl:absolute md:absolute xl:right-32   md:right-16 text-xl text-agray-500 flex">
                            {t('remote')}
                          </h2>
                        ) : (
                          <h2 className="xl:absolute md:absolute xl:right-32   md:right-16 text-xl text-agray-500 flex">
                            {job.attributes.countries.map((country, index) => (
                              <Fragment key={index}>
                                <p> {country} </p> &nbsp;
                              </Fragment>
                            ))}
                          </h2>
                        )}
                      </div>
                    ))}
                  </Fragment>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Ready></Ready>
    </>
  );
}
