import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function WeHaveItAll2() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <div className="pt-8 bg-white">
      <div className="md:max-w-3xl lg:max-w-5xl xl:max-w-7xl tv:max-w-none tv:mx-[336px] mx-auto">
        <div className="pt-8 overflow-hidden m-auto">
          <div className="m-auto top-0 w-full text-center">
            <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t("services")}
            </span>
            <h2 className="text-5xl text-nblue-900 font-bold">
              {t("we-have-all")}
            </h2>
            <p className="font-light mt-4  text-lg text-agray-500 w-4/5 xl:w-1/2 m-auto ">
              {t("we-have-all-desc")}
            </p>
          </div>
          <div className="relative mt-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 px-[15px] tv:px-0">
              <Link
                to={
                  currentLanguage === "en"
                    ? "/services/cloud-services"
                    : "/es/services/cloud-services"
                }
                className="hover:scale-105"
              >
                <div className="col-span-1  py-4 lg:max-w-none lg:mx-0 lg:px-0 md:mx-1">
                  <div className="bg-agray-100 h-full pb-8 md:pb-12 w-full rounded-3xl hover:border-ablue-500 hover:border-2">
                    <div className="pl-4 pt-10">
                      <img
                        className="w-28  h-20"
                        src={require("../assets/img/services/services-cloud-color.png")}
                        alt="cloud"
                      />
                      <div className="mt-6">
                        <h2 className="text-2xl text-nblue-900 font-bold">
                          {t("cloud-services")}
                        </h2>
                        <p className="font-light mt-4  text-lg text-agray-500  w-5/6 ">
                          {t("cloud-services-desc")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to={
                  currentLanguage === "en"
                    ? "/services/ai-integration-services"
                    : "/es/services/ai-integration-services"
                }
                className="hover:scale-105"
              >
                <div className="col-span-1 py-4 lg:max-w-none lg:mx-0 lg:px-0 md:mx-1">
                  <div className="bg-agray-100 h-full pb-8 md:pb-12 w-full rounded-3xl hover:border-ablue-500 hover:border-2">
                    <div className="pl-4 pt-10">
                      <img
                        className="w-28  h-20"
                        src={require("../assets/img/services/services-ai-color.png")}
                        alt="AI"
                      />
                      <div className="mt-6">
                        <h2 className="text-2xl text-nblue-900 font-bold">
                          {t("ai-integration")}
                        </h2>
                        <p className="font-light mt-4  text-lg text-agray-500  w-5/6 ">
                          {t("ai-integration-desc")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to={
                  currentLanguage === "en"
                    ? "/services/analytics-services"
                    : "/es/services/analytics-services"
                }
                className="hover:scale-105"
              >
                <div className="col-span-1 py-4 lg:max-w-none lg:mx-0 lg:px-0 md:mx-1">
                  <div className="bg-agray-100 h-full pb-8 md:pb-12 w-full rounded-3xl hover:border-ablue-500 hover:border-2">
                    <div className="pl-4 pt-10">
                      <img
                        className="w-28  h-20"
                        src={require("../assets/img/services/services-analytics-color.png")}
                        alt="Analytics"
                      />
                      <div className="mt-6">
                        <h2 className="text-2xl text-nblue-900 font-bold">
                          {t("analytics")}
                        </h2>
                        <p className="font-light mt-4  text-lg text-agray-500  w-5/6 ">
                          {t("analytics-desc")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                to={
                  currentLanguage === "en"
                    ? "/services/ux-ui-services"
                    : "/es/services/ux-ui-services"
                }
                className="hover:scale-105"
              >
                <div className="col-span-1 py-4 lg:max-w-none lg:mx-0 lg:px-0 md:mx-1">
                  <div className="bg-agray-100 h-full pb-8 md:pb-12 w-full rounded-3xl hover:border-ablue-500 hover:border-2">
                    <div className="pl-4 pt-10">
                      <img
                        className="w-28  h-20"
                        src={require("../assets/img/services/services-ux-color.png")}
                        alt="ux"
                      />
                      <div className="mt-6">
                        <h2 className="text-2xl text-nblue-900 font-bold">
                          {t("user-experience")}
                        </h2>
                        <p className="font-light mt-4  text-lg text-agray-500  w-5/6 ">
                          {t("user-experience-desc")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
