import { useRef, useState } from "react";

import Loading from "./shared/Loading";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ContactUs() {
  const { t, i18n } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(true);
  const [emailValid, setEmailValid] = useState(false);
  const [emailTouched, setemailTouched] = useState(false);

  const [loading, setLoading] = useState(false);

  const [sent, setSent] = useState(0);

  const [errorMessage, setErrorMessage] = useState("error-contact-us");
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const messageInputRef = useRef();

  const captcha = useRef();

  const onChange = (e) => {
    if (emailInputRef.current.value !== "") {
      setemailTouched(true);
      let re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(emailInputRef.current.value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    } else {
      setEmailValid(false);
    }

    if (
      nameInputRef.current.value !== "" &&
      emailInputRef.current.value !== "" &&
      emailValid === true &&
      emailTouched === true &&
      messageInputRef.current.value !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const validationEmail = emailTouched && !emailValid;

  const onSubmitHandler = (e) => {
    setLoading(true);
    e.preventDefault();
    if (captcha.current.getValue()) {
      //captcha.current.reset();
      const data = {
        senderEmail: e.target.Email.value,
        subject: "Contact message",
        body: e.target.Message.value,
        name: e.target.Name.value,
      };

      const xhr = new XMLHttpRequest();
      xhr.open("POST", process.env.REACT_APP_MAIL_URL);

      xhr.setRequestHeader("x-api-key", process.env.REACT_APP_MAIL_API_KEY);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            nameInputRef.current.value = "";
            emailInputRef.current.value = "";
            messageInputRef.current.value = "";
            setSent(1);
            setIsDisabled(true);
            setemailTouched(false);
            setLoading(false);
            window.gtag("event", "conversion", {
              send_to: "AW-972900822/JvlkCIaomtYBENaT9c8D",
            });
            window.gtag("event", "conversion", {});
            window.hj("event", "conversion", {});

            navigate(currentLanguage === "en" ? `/thankyou` : `/es/thankyou`);
          } else {
            setSent(2);
            setLoading(false);
            setErrorMessage("error-contact-us");
          }
        }
      };

      xhr.send(JSON.stringify(data));
    } else {
      setSent(2);
      setErrorMessage("error-recaptcha");
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="relative py-16 m-auto bg-white">
        <div className="mt-24 mx-4 xl:mx-16 md:mx-10">
          <div className="xl:mx-auto xl:max-w-7xl xl:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="mt-12 sm:my-8 mx-8 xl:mt-0 lg:col-start-1 ">
              <div>
                <div className="mt-6 text-center lg:text-left">
                  <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                    {t("contact")}
                  </span>
                  <h1 className="text-5xl text-nblue-900 font-bold">
                    {t("tell-us-more")}
                  </h1>
                  <p className="font-light mt-4 text-lg text-agray-500 m-auto">
                    {t("contact-desc")}
                  </p>
                </div>
              </div>
              <form onSubmit={onSubmitHandler} method="POST" className="my-14">
                <div className="my-6">
                  <p className="text-ablue-900 py-2 font-bold">{t("name")}*</p>
                  <input
                    // id="name"
                    name="Name"
                    placeholder={t("enter-name")}
                    ref={nameInputRef}
                    onChange={onChange}
                    autoComplete="given-name"
                    className="p-3 w-full bg-agray-100 rounded-lg"
                  ></input>
                </div>
                <div className="my-6">
                  <p className="text-ablue-900 py-2 font-bold">{t("email")}*</p>
                  <input
                    // id="email"
                    name="Email"
                    type="email"
                    placeholder={t("enter-email")}
                    ref={emailInputRef}
                    onChange={onChange}
                    autoComplete="email"
                    className="p-3 w-full bg-agray-100 rounded-lg"
                  ></input>
                </div>
                <div className="my-6">
                  <p className="text-ablue-900 py-2 font-bold">
                    {t("project-detail")}*
                  </p>
                  <input
                    name="Message"
                    placeholder={t("enter-project-detail")}
                    ref={messageInputRef}
                    onChange={onChange}
                    className="p-3 w-full bg-agray-100 rounded-lg"
                  ></input>
                  {validationEmail && (
                    <p className="text-red-500 text-sm">{t("invalid-email")}</p>
                  )}
                </div>
                <ReCAPTCHA
                  className="mt-1"
                  sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                  ref={captcha}
                />
                {loading ? (
                  <div className="flex justify-center">
                    <Loading></Loading>
                  </div>
                ) : (
                  <div className="my-14">
                    <button
                      disabled={isDisabled}
                      type="submit"
                      className="hover:bg-nblue-600 bg-ablue-600 cursor-pointer w-64 lg:w-full m-auto h-10 flex items-center text-center justify-center px-6 py-[17px] font-manrope font-bold text-base rounded-3xl text-white"
                    >
                      {t("send")}
                    </button>
                    {sent === 1 && (
                      <p className="font-light text-center mt-6  text-lg text-agray-500 ">
                        {" "}
                        {t("thanks-contacting-us")}
                      </p>
                    )}
                    {sent === 2 && (
                      <p className="font-light text-center mt-6 text-lg text-agray-500 ">
                        {t(errorMessage)}
                      </p>
                    )}
                  </div>
                )}
              </form>
            </div>
            <div className="mt-10 xl:mt-0 xl:px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
              <div className="w-full h-full ">
                <img
                  className=""
                  src={require("../assets/img/home/contactdesc.png")}
                  alt="contact us"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
