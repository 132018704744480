import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function OurWorkHero({ noClick, language }) {
  SwiperCore.use([Navigation, Autoplay]);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();

  const [selectedCategory] = useState("All");

  const projects = [
    {
      image: require("../assets/img/home/work-sp-problems.png"),
      alt: "SuperPortero",
      slug: "super-portero",
      category: "Sports",
    },
    {
      image: require("../assets/img/home/work-nativis-problems.png"),
      alt: "Nativis Projex",
      slug: "nativis-projex",
      category: "Travel",
    },
    {
      image: require("../assets/img/home/work-garre-problems.png"),
      alt: "Garré Airlines",
      slug: "garre",
      category: "Travel",
    },
    {
      image: require("../assets/img/home/work-dogit-problems.png"),
      alt: "DogIt",
      slug: "dog-it",
      category: "Pets",
    },
  ];

  const filteredProjects = selectedCategory === "All" 
    ? projects 
    : projects.filter(project => project.category === selectedCategory);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  return (
    <div className="relative pt-16 pb-16 m-auto bg-agray-100">
      <div className="m-auto top-0 w-full text-center">
        <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
          {t("our-work")}
        </span>
        <h1 className="text-5xl text-nblue-900 font-bold">
          {t("our-work-title")}
        </h1>
        <p className="font-light mt-4 text-lg text-agray-500 xl:w-1/2 md:w-3/4 sm:w-full xs:w-full m-auto ">
          {t("our-work-desc")}
        </p>
        
        {/* Category filter */}
        {/* <div className="mt-8 mb-8 flex flex-wrap justify-center gap-2">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-4 py-2 rounded-full text-sm font-semibold transition-colors duration-200 ${
                selectedCategory === category
                  ? "bg-ablue-600 text-white"
                  : "bg-white text-agray-500 hover:bg-ablue-100 border border-agray-300"
              }`}
            >
              {category}
            </button>
          ))}
        </div> */}

        <div className="my-8 xl:mt-0 lg:mt-0 md:mt-0">
          <Link
            to={
              noClick
                ? ""
                : currentLanguage === "en"
                ? "/our-work"
                : "/es/our-work"
            }
            className="justify-center w-72 h-14 border rounded-[28px] shadow-sm text-[14px] font-manrope m-auto text-white bg-gradient-to-r from-ablue-500 to-ablue-900 bg-animate-slow hover:from-agray-500 hover:to-agray-500 flex mt-10 items-center"
          >
            <label>{t("our-work-button")}</label>
          </Link>
        </div>
      </div>
      <div className="relative my-8 xl:mx-16 mx-4">
        <div className="hidden md:grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-4 xl:mx-auto xl:max-w-7xl xl:px-16 xl:grid-cols-2 md:max-w-7xl md:px-8 sm:max-w-sm">
          {filteredProjects.map((project, index) => (
            <button
              key={index}
              onClick={(e) =>
                navigate(
                  currentLanguage === "en"
                    ? `/our-work/detail/${project.slug}`
                    : `/es/our-work/detail/${project.slug}`
                )
              }
              className=""
            >
              <div className="overflow-hidden">
                <img
                  className="m-auto w-auto md:h-full md:w-3/4 object-cover transition hover:scale-105 duration-500"
                  src={project.image}
                  alt={project.alt}
                />
              </div>
            </button>
          ))}
        </div>
        <div className="block md:hidden mx-auto">
          <Swiper
            className="h-full"
            slidesPerView={1}
            modules={[Autoplay]}
            autoplay={{ delay: 2500 }}
            spaceBetween={200}
          >
            {filteredProjects.map((project, index) => (
              <SwiperSlide key={index}>
                <img
                  className="m-auto w-auto md:h-full md:w-3/4"
                  src={project.image}
                  alt={project.alt}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
