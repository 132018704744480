import "swiper/css";

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
} from "@heroicons/react/outline";
import { Fragment, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate, useParams } from "react-router-dom";

import { Autoplay } from "swiper";
import { Helmet } from "react-helmet";
import Loading from "../components/shared/Loading";
import Ready from "./Ready";
import { useCasesData } from "../useCasesData";
import { useTranslation } from "react-i18next";

export default function OurWorkDetail(props) {
  const [swiper, setSwiper] = useState(null);

  // SwiperCore.use([Navigation, Autoplay]);

  let { slug } = useParams();

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");

  const case_studies = useCasesData();
  const [index, setIndex] = useState(0);

  const [studies] = useState(case_studies);

  const [cases, setCases] = useState([]);

  useEffect(() => {
    try {
      window.scrollTo({ top: 0, behavior: "smooth" });

      setLoading(true);

      const filterSlug = studies.filter((item) => item.slug === slug);

      if (filterSlug.length === 0) {
        // Trigger your action here
        navigate("page-not-found");
      }

      setData(filterSlug[0]);

      const filter_cases = studies.filter((item) => item.slug !== slug);
      setCases(filter_cases);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, studies]);

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <Fragment>
      <Helmet>
        <title>{t("our-work")} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {!loading ? (
        <Fragment>
          <Helmet>
            <title>{data.name}</title>
            <link rel="canonical" href={window.location.href} />
            <meta name="description" content={data.desc} />
            <meta property="og:title" content={data.name} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={data.image_cover} />
            <meta property="og:description" content={data.desc} />
            <meta property="og:url" content={window.location.href} />
          </Helmet>
          <div className="relative pt-14 md:pt-36 xl:pt-28 m-auto bg-white">
            {/* MAIN */}
            <main className="mx-4 lg:mx-40 tv:mx-96 px-4 lg:px-0 my-8 pt-16">
              <div className="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-[128px]">
                <div className="md:hidden block lg:col-start-2 lg:w-[448px] lg:h-[512px] ">
                  <img
                    className="pb-[24px] rounded-2xl"
                    src={data.image_cover}
                    alt="cover"
                  />
                </div>
                <div className="lg:col-start-1 ">
                  <div className="">
                    <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                      {t("case-study")}
                    </span>
                    <h1 className="text-5xl text-nblue-900 font-bold">
                      {data.name}
                    </h1>
                    <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                      {t(data.challenge)}
                    </p>
                    <p className="mt-10 text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                      {t("plataform")}
                    </p>
                    <p className="font-light mt-2  text-lg text-agray-500">
                      {data.plataform}
                    </p>
                    <p className="mt-6 text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                      {t("services")}
                    </p>
                    <p className="font-light mt-2  text-lg text-agray-500">
                      {data.services}
                    </p>
                  </div>
                </div>
                <div className="hidden md:block lg:col-start-2 lg:w-[448px] lg:h-[512px] mx-auto">
                  <img
                    className="w-full md:w-[448px] md:h-[512px] h-96 rounded-2xl"
                    src={data.image_cover}
                    alt="cover"
                  />
                </div>
              </div>
            </main>
            {/* TECNOLOGIES */}
            <div className="bg-agray-100 px-4 lg:px-40 mt-0 md:mt-[120px]">
              <div className="m-auto pt-16 w-full text-center">
                <span className="text-md tracking-widest font-sans text-ablue-600 font-bold">
                  {t("tools")}
                </span>
                <h2 className="text-5xl text-nblue-900 font-bold">
                  {t("technologies")}
                </h2>
              </div>
              <div className="mx-auto pt-[64px] pt-16 md:pb-[141px] px-4 sm:px-6 ">
                <div
                  className={`grid grid-cols-2 gap-[24px] ${
                    data.tecnologies.length === 5
                      ? "md:grid-cols-5"
                      : data.tecnologies.length === 4
                      ? "md:grid-cols-4"
                      : "md:grid-cols-3"
                  }`}
                >
                  {data.tecnologies.map((item, index) => (
                    <div
                      className="col-span-1 flex justify-center md:col-span-1 lg:col-span-1"
                      key={index}
                    >
                      <div>
                        <img
                          className="w-36 h-28"
                          src={item.img}
                          alt={item.name}
                        />
                        <p className=" text-sm text-agray-500  text-center">
                          {item.name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* CHALLENGES */}
            <div className="mx-4 lg:mx-40 tv:mx-96 px-4 lg:px-0 my-8 pt-16">
              <div className="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-[128px]">
                <div className="lg:col-start-1 ">
                  <div className="">
                    <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                      {t("challenges")}
                    </span>
                    <h2 className="text-5xl text-nblue-900 font-bold">
                      {t("problems-solve")}
                    </h2>
                    <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                      {t(data.solution)}
                    </p>
                    <div className="pt-0 md:pt-12">
                      {data.checkbuttons.map((item, index) => (
                        <div className="flex gap-[8px]" key={index}>
                          <div>
                            <CheckIcon className="mt-5 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                          </div>
                          <div>
                            <p className="font-light mt-4 text-lg text-agray-500">
                              {t(item.desc)}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="lg:col-start-2 lg:w-[448px] lg:h-[512px] mx-auto">
                  <img
                    className="mt-10 md:mt-0 w-full md:w-[448px] md:h-[512px] h-96 rounded-2xl"
                    src={data.image_problems}
                    alt="problems"
                  />
                </div>
              </div>
            </div>
            {/* SOLUTIONS */}
            <div className="bg-agray-100 px-4 lg:px-40 tv:px-96 px-4 lg:px-0 py-20">
              <div className="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-[128px]">
                <div className="hidden md:block lg:col-start-1 ">
                  <img
                    className="my-8 md:mt-0 w-full md:w-[448px] md:h-[512px] h-96 rounded-2xl"
                    src={data.image_results}
                    alt="results"
                  />
                </div>
                <div className="lg:col-start-2 lg:w-[448px] tv:w-full">
                  <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                    {t("solutions")}
                  </span>
                  <h2 className="text-5xl text-nblue-900 font-bold">
                    {t("final-results")}
                  </h2>
                  <p className="font-light mt-4 text-lg text-agray-500">
                    <div
                      className="content text-justify"
                      dangerouslySetInnerHTML={{ __html: t(data.outcome) }}
                    ></div>
                    {/* {t(data.outcome)} */}
                  </p>
                </div>
                <div className="block md:hidden lg:col-start-1 ">
                  <img
                    className="mt-10 md:mt-0 w-full md:w-[448px] md:h-[512px] h-96 rounded-2xl"
                    src={data.image_results}
                    alt="results"
                  />
                </div>
              </div>
            </div>
            {/* SLIDER */}
            <div className="mx-4 lg:mx-40 tv:mx-96 px-4 lg:px-0 my-8 pt-16">
              <div className="lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-[128px]">
                <div className="lg:col-start-1 ">
                  <span className="text-md tracking-widest font-sans text-ablue-600 font-bold uppercase">
                    {t("our-work")}
                  </span>
                  <h2 className="text-5xl text-nblue-900 font-bold">
                    {cases[index].name}
                  </h2>
                  <p className="font-light mt-4 text-lg text-agray-500 m-auto line-clamp-3">
                    {t(cases[index].challenge)}
                  </p>
                  <div className="mt-10">
                    <button
                      className="justify-center w-64 h-14 px-[39px] py-[12px] border rounded-[28px] shadow-sm text-[14px] font-manrope text-white bg-gradient-to-r from-ablue-500 to-ablue-900 bg-animate-slow hover:from-agray-500 hover:to-agray-500 flex my-8 items-center"
                      onClick={(e) =>
                        navigate(
                          currentLanguage === "en"
                            ? `/our-work/detail/${cases[index].slug}`
                            : `/es/our-work/detail/${cases[index].slug}`
                        )
                      }
                    >
                      {t("see-more")}
                    </button>
                  </div>
                </div>
                <div className="lg:col-start-2 lg:w-[448px] lg:h-[512px] mx-auto">
                  <Swiper
                    className="  h-96  rounded-3xl "
                    slidesPerView={1}
                    onInit={(ev) => {
                      setSwiper(ev);
                      setIndex(ev.activeIndex);
                    }}
                    modules={[Autoplay]}
                    autoplay={{ delay: 2000 }}
                    spaceBetween={50}
                    onSlideChange={(e) => setIndex(e.realIndex)}
                    onSwiper={(swiper) => setIndex(swiper.activeIndex)}
                  >
                    {cases.map((item, index) => (
                      <SwiperSlide
                        key={index}
                        onClick={(e) =>
                          navigate(
                            currentLanguage === "en"
                              ? `/our-work/detail/${cases[index].slug}`
                              : `/es/our-work/detail/${cases[index].slug}`
                          )
                        }
                      >
                        <img
                          className="m-auto my-10 md:my-0"
                          src={item.image_cover}
                          alt="cover"
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <div className="flex h-10 w-auto float-left mt-6 text-ablue-900">
                    <button
                      onClick={() => {
                        swiper.slidePrev();
                      }}
                      id="swiper-backward"
                    >
                      <ArrowLeftIcon className="mr-8 h-9 w-9 hover:text-sky-400"></ArrowLeftIcon>
                    </button>
                    <button
                      onClick={() => {
                        swiper.slideNext();
                      }}
                      id="swiper-forward"
                    >
                      <ArrowRightIcon className="h-9 w-9 hover:text-sky-400"></ArrowRightIcon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-8">
            <Ready></Ready>
            </div>
          </div>
        </Fragment>
      ) : (
        <Loading />
      )}
    </Fragment>
  );
}
