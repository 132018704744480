import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";

export default function MainContent() {
  SwiperCore.use([Navigation, Autoplay]);

  const techs = [
    {
      alt: "AWS",
      image: require("../assets/img/tecp/tecp-aws.png"),
    },
    {
      alt: "Google",
      image: require("../assets/img/tecp/tecp-google.png"),
    },
    {
      alt: "Digital Ocean",
      image: require("../assets/img/tecp/tecp-digitalocean.png"),
    },
    {
      alt: "Microsoft",
      image: require("../assets/img/tecp/tecp-microsoft.png"),
    },
    {
      alt: "Shopify",
      image: require("../assets/img/tecp/tecp-shopify.png"),
    },
  ]

  return (
    <div className="relative mx-auto px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
      <div className="hidden mt-6 xl:flex lg:flex md:flex justify-center">
        {
          techs.map((tech) => (
            <div className="flex-auto">
              <img
                className="m-auto"
                src={tech.image}
                alt={tech.alt}
              />
            </div>
          ))
        }
      </div>
      <div className="block md:hidden mx-auto w-full m-4 p-4">
        <Swiper
          className="h-full"
          slidesPerView={1}
          modules={[Autoplay]}
          autoplay={{ delay: 2500 }}
          spaceBetween={200}
        >
          {techs.map((tech) => (
            <SwiperSlide
              className="flex-auto"
            >
              <img
                className="m-auto"
                src={tech.image}
                alt={tech.alt}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
