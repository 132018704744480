import 'swiper/css';

import { Link, useLocation } from 'react-router-dom';
import SwiperCore, { Autoplay, Navigation } from 'swiper';

import { CheckIcon } from '@heroicons/react/outline';
import ContactUs from './ContactUs';
import FAQs from './FAQs';
import { Helmet } from 'react-helmet';
import Ready from './Ready';
import WeHaveItAll from './WeHaveItAll';
import WhyChooseUs from './WhyChooseUs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ServicesNearShore(props) {
  SwiperCore.use([Navigation, Autoplay]);

  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qParam = searchParams.get('q');

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <>
      <Helmet>
        <title>{t('nearshore-services')} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t('nearshore-service-desc')} />
        <meta property="og:title" content={t('nearshore-services')} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t('nearshore-service-desc')} />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:image"
          content={require('../assets/img/services/hero-nearshore.png')}
        />
      </Helmet>
      <div className={`bg-agray-100 pt-24 `}>
        <div className="px-4 pb-16 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto md:grid md:grid-cols-2 md:gap-6">
          <img
            className="m-auto order-2"
            src={require('../assets/img/services/hero-nearshore.png')}
            alt="nearshore_hero"
          />
          <div className="m-auto text-left w-full">
            <div className="pb-4">
              <span className="text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                {t('nearshore-title')}
              </span>
            </div>
            <h1 className="xl:text-5xl md:text-3xl sm:text-2xl xs: text-lg text-nblue-900 font-bold leading-tight">
              {t('nearshore-subtitle')}
            </h1>
            <p className="font-light mt-4  text-lg text-agray-500 w-10/12 ">
              {/* {t("nearshore-subtitle-2")} */}
            </p>
            <div className="mt-9">
              <Link
                to={currentLanguage === 'en' ? '/contact' : '/es/contact'}
                className="hover:bg-nblue-600 bg-ablue-600 w-72 flex items-center justify-center px-[22px] py-[17px]  text-sm font-manrope rounded-[28px] text-white"
              >
                <label>{t('next-project')}</label>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="px-4 pb-16 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
            <div className="relative pt-16 py-12 overflow-hidden m-auto">
              <div className="mt-24">
                <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense gap-4">
                  <div className="order-2">
                    <div>
                      <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                        {t('nearshore-hero-title')}
                      </span>
                      <h2 className="text-5xl text-nblue-900 font-bold leading-12 mt-2">
                        {t('nearshore-hero-subtitle')}
                      </h2>
                      <p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                        {t('nearshore-content')}
                      </p>
                    </div>
                  </div>
                  <img
                    className="max-w-80 my-8 md:my-auto"
                    src={require('../assets/img/services/nearshoredesc.png')}
                    alt="nearshore_desc"
                  />
                </div>
              </div>
            </div>
            <div className="relative pb-[127px] overflow-hidden m-auto">
              <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense gap-4">
                <div>
                  <div className="">
                    <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                      {t('nearshore-benefit-title')}
                    </span>
                    <h2 className="text-5xl text-nblue-900 font-bold mt-2">
                      {t('nearshore-benefit-subtitle')}
                    </h2>
                    {/*<p className="font-light mt-4  text-lg text-agray-500  m-auto ">
                      {t("nearshore-benefit-subtitle")}
                    </p>*/}
                    <div className="mt-12">
                      <div className="flex gap-[8px]">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('nearshore-benefit1')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('nearshore-benefit2')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('nearshore-benefit3')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('nearshore-benefit4')}
                          </p>
                        </div>
                      </div>
                      <div className="flex gap-[8px] mt-6">
                        <div>
                          <CheckIcon className="mt-1 w-[22px] h-[22px] float-bottom bg-agray-500 text-white rounded-full"></CheckIcon>
                        </div>
                        <div>
                          <p className="font-light text-lg text-agray-500">
                            {t('nearshore-benefit5')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <img
                  className="max-w-80 mx-auto my-8 mt-9 md:mt-0 my-auto"
                  src={require('../assets/img/services/nearshorebenefits.png')}
                  alt="nearshore_benefits"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 py-24 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
          <div className="m-auto w-full text-center">
            <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t('team')}
            </span>
            <h2 className="text-5xl text-nblue-900 font-bold">
              {t('nearshore-roles-title')}
            </h2>
            <p className="font-light mt-4  text-lg text-agray-500 w-4/5 xl:w-1/2 m-auto  ">
              {t('nearshore-roles-desc')}
            </p>
            <div className="max-w-6xl mx-auto pt-10 pb-[16px]">
              <div className="mt-6 grid grid-cols-4  md:grid-cols-4 w-fit m-auto">
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <div>
                    <img
                      className="w-36 my-auto"
                      src={require('../assets/img/services/team-dev.png')}
                      alt="devs"
                    />
                    <p className=" text-sm text-agray-500  text-center">
                      {t('developers')}
                    </p>
                  </div>
                </div>
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <div>
                    <img
                      className="w-36 my-auto"
                      src={require('../assets/img/services/team-design.png')}
                      alt="designers"
                    />
                    <p className=" text-sm text-agray-500  text-center">
                      {t('designers')}
                    </p>
                  </div>
                </div>
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <div>
                    <img
                      className="w-36 my-auto"
                      src={require('../assets/img/services/team-tester.png')}
                      alt="tester"
                    />
                    <p className="text-sm text-agray-500  text-center">
                      {t('testers')}
                    </p>
                  </div>
                </div>
                <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
                  <div>
                    <img
                      className="w-36 my-auto"
                      src={require('../assets/img/services/team-pm.png')}
                      alt="pm"
                    />
                    <p className=" text-sm text-agray-500  text-center">
                      {t('project-managers')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {qParam === 'landing' && <FAQs></FAQs>}
        <WeHaveItAll></WeHaveItAll>
      </div>
      {qParam === 'landing' && <WhyChooseUs></WhyChooseUs>}
      {qParam === 'landing' ? <ContactUs></ContactUs> : <Ready></Ready>}
    </>
  );
}
