import { ArrowRightIcon } from "@heroicons/react/outline";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

const EmailAdressForm = ({ status, message, onValidated }) => {
  const emailInputRef = useRef();
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    onValidated({
      EMAIL: emailInputRef.current.value,
    });
    emailInputRef.current.value = "";
  };

  return (
    <div className="px-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
      <div className="relative grid grid-cols-12 mt-10 pb-14 pt-28 gap-2">
        <img
          className="h-14 col-span-12 lg:col-span-6 md:col-span-4"
          src={require("../assets/img/logo.png")}
          alt="alluxi logo"
        />
        <h3 className="my-auto font-bold text-nblue-900 col-span-12 xl:pt-0 lg:col-span-3 lg:pt-0 md:col-span-4 md:pt-0">
          {t("get-tips")}
        </h3>
        <div className="col-span-12 lg:col-span-3 md:col-span-4">
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="flex bg-agray-100 rounded-xl w-full"
          >
            <input
              placeholder={t("email-adress")}
              ref={emailInputRef}
              name="MERGE0"
              className=" py-4 h-18 w-full pl-6 bg-transparent"
            ></input>
            <button type="submit">
              <ArrowRightIcon className="h-9 w-9 text-ablue-600 pr-4"></ArrowRightIcon>
            </button>
          </form>
          {status === "success" && (
            <p className="text-agray-500 pt-6">{t("thanks-sub")}</p>
          )}
          {status === "sending" && (
            <p className="text-agray-500 pt-6">{t("sending")}</p>
          )}
          {status === "error" && <p className="text-red-500 pt-6">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default EmailAdressForm;
