import React from 'react';

const VideoModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div
        className="relative bg-white p-5 h-1/2 rounded-md max-w-4xl w-11/12"
        onClick={(e) => e.stopPropagation()}
      >
        <span
          className="absolute top-2 right-2 text-2xl text-gray-500 cursor-pointer"
          onClick={onClose}
        >
          &times;
        </span>
        <div className="aspect-w-16 aspect-h-9 p-6">

          <iframe width="100%" height="350px" src="https://www.youtube.com/embed/VzXM9e1Ymmg?si=jBzeFuxHRsSYSqOF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;