import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Thankyou() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <Fragment>
      <Helmet>
        <title>{t("thankyou")} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("thankyou-desc")} />
        <meta property="og:title" content={t("thankyou")} />
        <meta property="og:type" content="article" />
        <meta
          property="og:image"
          content={require("../assets/img/hero-nearshore.png")}
        />
        <meta property="og:description" content={t("thankyou-desc")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="relative pt-44 m-auto bg-white ">
        <div className="m-auto top-0 w-11/12 xl:w-7/12 text-center">
          <p className="text-md pt-4 tracking-widest font-semibold text-ablue-600 font-bold">
            {t("contact")}
          </p>
          <h1 className="text-4xl xl:text-5xl text-nblue-900 font-bold">
            {t("thankyou-sub")}
          </h1>
          <p className="font-light mt-4  text-lg text-agray-500  ">
            {t("thankyou-desc")}
          </p>
          <Link
            to={currentLanguage === "en" ? "/" : "/es"}
            className="mt-10 m-auto flex items-center justify-center w-[289px] h-[56px]  text-sm font-base rounded-3xl text-white bg-ablue-600 "
          >
            {t("back-home")}
          </Link>

          <img
            className="w-fit xl:h-full md:mt-9 md:h-80 md:ml-2"
            src={require("../assets/img/hero-nearshore.png")}
            alt="thankyou"
          />
        </div>
      </div>
    </Fragment>
  );
}
