import { useTranslation } from "react-i18next";

export default function Awards({ noClick }) {
  const { t } = useTranslation();

  return (
    <div
      className={`mx-auto ${noClick ? "py-16" : "pb-8 md:pt-16"} bg-agray-100`}
    >
      <main className="px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
        <div className="flex flex-wrap md:flex-nowrap justify-center sm:justify-between gap-8">
          <a
            href="https://clutch.co/press-releases/awards-best-b2b-companies-latin-america-2020"
            target="_blank"
            rel="noreferrer"
            className="wx-auto hover:scale-105"
          >
            <img
              className="h-28 object-scale-down"
              src={require("../assets/img/award/award-clutch.png")}
              alt="Clutch"
            />
          </a>
          <a
            href="https://bestplacetocode.com/es"
            target="_blank"
            rel="noreferrer"
            className="wx-auto hover:scale-105"
          >
            <img
              className="h-28 object-scale-down"
              src={require("../assets/img/award/award-sg.png")}
              alt="software guru"
            />
          </a>
          <a
            href="https://techbehemoths.com/awards-2022/custom-software-development/mexico"
            target="_blank"
            rel="noreferrer"
            className="wx-auto"
          >
            <img
              className="h-28 object-scale-down hover:scale-105"
              src={require("../assets/img/award/banner-service-white.png")}
              alt="TechBehemoths"
            />
          </a>
          <div className="flex flex-col w-full md:w-auto justify-center order-first md:order-last">
            {/* <span className="text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t("why-choose-us")}
            </span> */}
            <h2 className="text-4xl xl:text-5xl lg:text-5xl md:text-3xl text-nblue-900 font-bold ">
              {t("awards")}
            </h2>
          </div>
        </div>
      </main>
    </div>
  );
}
