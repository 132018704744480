import { useCallback, useEffect, useState } from "react";

import BlogWidget from "./BlogWidget";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Loading from "../shared/Loading";
import Ready from "../Ready";
import { useTranslation } from "react-i18next";

export default function Blog(props) {
  const [blogData, setBlogData] = useState([]);

  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);

  const fetchBlogData = useCallback(async (language) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL}?key=${process.env.REACT_APP_KEY}&filter=tag:${language}`
      );
      if (!response.ok) {
        throw new Error("Something went wrong");
      }
      const data = await response.json();
      const transformedBlog = data.posts.map((blogData) => {
        return {
          id: blogData.uuid,
          slug: blogData.slug,
          title: blogData.title,
          description: blogData.excerpt.replace(/\n/g, ""),
          image: blogData.feature_image,
        };
      });
      setBlogData(transformedBlog);
      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    if (i18n.language === "es-ES" || i18n.language === "es") {
      fetchBlogData("es");
    } else {
      fetchBlogData("en");
    }
  }, [fetchBlogData, i18n.language]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [loading]);

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <>
      <Helmet>
        <title>Blog | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("blog-desc")} />
        <meta property="og:title" content="Blog" />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t("blog-desc")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <div className="relative pt-44 m-auto bg-white ">
          <div className="m-auto top-0 xl:w-full w-5/6 text-center">
            <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              BLOG
            </span>
            <h1 className="text-4xl xl:text-5xl text-nblue-900 font-bold">
              {t("blog-title")}
            </h1>
            <p className="font-light mt-4  text-lg text-agray-500 xl:w-1/2 m-auto ">
              {t("blog-desc")}
            </p>
          </div>

          <div className="hidden md:block relative mt-10 mx-4 xl:mx-40 md:mx-16">
            <div className="grid grid-cols-3 gap-8">
              {blogData.map((blog, index) => (
                <Link
                  to={`/blog/detail/${blog.slug}`}
                  className="col-span-3 xl:col-span-1 lg:col-span-1 md:col-span-1 hover:scale-105"
                  key={index}
                >
                  <div className="px-4 max-w-xl mx-auto sm:px-6 lg:max-w-none lg:mx-0 lg:px-0 md:px-0">
                    <img
                      src={
                        blog.image ??
                        require("../../assets/img/blog-default.png")
                      }
                      alt="blog"
                      className="m-auto h-52 rounded-xl "
                    ></img>
                    <div className="mt-6 text-center">
                      <h2 className="text-2xl text-nblue-900 font-bold line-clamp-2">
                        {blog.title}
                      </h2>
                      <p className="font-light mt-4 text-lg text-agray-500 line-clamp-2">
                        {blog.description}
                      </p>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <div className="flex md:hidden justify-center">
            <BlogWidget></BlogWidget>
          </div>
          <Ready></Ready>
        </div>
      )}
    </>
  );
}
