import { Fragment, useEffect } from "react";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy(props) {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <Fragment>
      <Helmet>
        <title>{t("privacy-policy")} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("pp-paragraph-1")} />
        <meta property="og:title" content={t("privacy-policy")} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t("pp-paragraph-1")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="pt-44">
        <div className="px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
          <h1 className="pt-2 text-4xl xl:text-5xl text-center text-nblue-900 font-bold">
            {t("privacy-policy")}
          </h1>
          <p className="pt-4 font-light text-justify mt-4 text-lg text-agray-500">
            {t("pp-paragraph-1")}
            <br />
            <br />
            {t("pp-paragraph-2")}
            <br />
            <br />
            {t("pp-paragraph-3")}
            <br />
            <br />
            {t("pp-paragraph-4")}
            <br />
            <br />
            {t("pp-paragraph-5")}
            <br />
            <br />
            {t("pp-paragraph-6")}
            <br />
            <br />
            {t("pp-paragraph-7")}
            <br />
            <br />
            {t("pp-paragraph-8")}
            <br />
            <br />
            {t("pp-paragraph-9")}
            <br />
            <br />
            {t("pp-paragraph-10")}
            <br />
            <br />
            {t("pp-paragraph-11")}
            <br />
            <br />
            {t("pp-paragraph-12")}
            <br />
            <br />
            {t("pp-paragraph-13")}
            <br />
            <br />
            {t("pp-paragraph-14")}
            <br />
            <br />
            {t("pp-paragraph-15")}
            <br />
            <br />
            {t("pp-paragraph-16")}
            <br />
            <br />
            {t("pp-paragraph-17")}
            <br />
            <br />
            {t("pp-paragraph-18")}
            <br />
            <br />
            {t("pp-paragraph-19")}
          </p>
        </div>
      </div>
    </Fragment>
  );
}
