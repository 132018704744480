import 'swiper/css';

import SwiperCore, { Autoplay, Navigation } from 'swiper';

import { CheckIcon } from '@heroicons/react/outline';
// import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// import {
//   ArrowNarrowLeftIcon,
//   ArrowNarrowRightIcon,
// } from '@heroicons/react/outline';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { StarIcon } from '@heroicons/react/solid';



export default function WhyChooseUs() {
  SwiperCore.use([Navigation, Autoplay]);

  const { t } = useTranslation();
  // const [swiper, setSwiper] = useState({});

  return (
    <div className="bg-agray-100">
      <div className="px-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
        <div className="py-12 m-auto block md:grid md:grid-cols-2 md:gap-4">
          <div className="text-left">
            <div className="xl:px-4">
              <div className="pb-4">
                <span className="text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                  {t('why-choose-us')}
                </span>
              </div>
              <h2 className="text-4xl text-nblue-900 font-bold">
                {t('our-customers-love')}
              </h2>
              <div className="pt-2 xl:w-4/6">
                <div className="flex">
                  <div className="w-5">
                    <CheckIcon className="mt-5 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                  </div>
                  <p className="font-light mt-4 ml-2 text-lg text-agray-500 md:w-1/2 xl:w-full">
                    {t('we-use-modern-tool')}
                  </p>
                </div>
                <div className="flex">
                  <div className="w-5">
                    <CheckIcon className="mt-5 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                  </div>
                  <p className="font-light mt-4 ml-2 text-lg text-agray-500 md:w-1/2 xl:w-full">
                    {t('we-improve')}
                  </p>
                </div>
                <div className="flex">
                  <div className="w-5">
                    <CheckIcon className="mt-5 float-bottom bg-agray-200 text-white w-5 h-5 rounded-full"></CheckIcon>
                  </div>
                  <p className="font-light mt-4 ml-2 text-lg text-agray-500 md:w-1/2 xl:w-full">
                    {t('we-look')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="m-auto w-full my-16 md:my-0">
            <div
              className="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="12"
              data-height="400"
              data-nofollow="true"
              data-expandifr="false"
              data-scale="100"
              data-header-color="#3973E7"
              data-footer-color="#3973E7"
              data-primary-color="#f44336"
              data-secondary-color="#f44336"
              data-reviews="274162,274160,271886,187236,177524"
              data-clutchcompany-id="736921"
            ></div>
{/* 
            <div className="pl-4 bg-white pt-4 pb-8 rounded-xl w-full sm:w-full">
              <Swiper
                className=" bg-white "
                slidesPerView={1}
                onInit={(ev) => {
                  setSwiper(ev);
                }}
                modules={[Autoplay]}
                autoplay={{ delay: 2000 }}
                spaceBetween={50}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
              >


                <SwiperSlide>
                  <p className="font-light m-2 text-lg text-agray-500 p-2 w-11/12">
                    {t('swiper-zubillaga')}
                  </p>
                  <div className="flex">
                    <div className="flex m-4">
                      <img
                        className="h-12 w-12 mt-1 bg-ablue-500 rounded-full"
                        src={require('../assets/img/zubillaga.png')}
                        alt="SG"
                      />
                      <div>
                        <p className="text-lg text-nblue-900 font-bold pl-2">
                          E. Zubillaga
                        </p>
                        <p className="font-light text-lg text-agray-500 pl-2">
                          CEO Zequenze LLC
                        </p>
                      </div>
                    </div>
                    <div className="flex mt-4 m-4">
                      <StarIcon className="h-8 w-8 text-ared-500"></StarIcon>
                      <StarIcon className="h-8 w-8 text-ared-500"></StarIcon>
                      <StarIcon className="h-8 w-8 text-ared-500"></StarIcon>
                      <StarIcon className="h-8 w-8 text-ared-500"></StarIcon>
                      <StarIcon className="h-8 w-8 text-ared-500"></StarIcon>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>  */}
            {/* <div className="flex w-auto float-left my-4 text-ablue-900">
              <button onClick={() => swiper.slidePrev()} id="swiper-forward">
                {' '}
                <ArrowNarrowLeftIcon className="mr-8 w-9 hover:text-sky-400"></ArrowNarrowLeftIcon>
              </button>
              <button onClick={() => swiper.slideNext()} id="swiper-forward">
                {' '}
                <ArrowNarrowRightIcon className="w-9 hover:text-sky-400"></ArrowNarrowRightIcon>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
