import AboutUsLanding from './components/AboutUsLanding';
import Awards from './components/Awards';
import ContactUs from './components/ContactUs';
import FAQs from './components/FAQs';
import MainContent from './components/MainContent';
import MainHero from './components/MainHero';
import Navbar from './components/Navbar';
import OurWorkHero from './components/OurWorkHero';
import Ready from './components/Ready';
import WhyChooseUs from './components/WhyChooseUs';
import { useRef } from 'react';

export default function Landing() {
  const scrollRef = useRef();
  return (
    <div className="bg-white">
      <main>
        <Navbar noLinks={1} />
        <MainHero noClick={1} scrollRef={scrollRef}></MainHero>
        <MainContent noClick={1} />
        <OurWorkHero></OurWorkHero>
        <AboutUsLanding></AboutUsLanding>
        <Ready noClick={1}></Ready>
        <WhyChooseUs />
        <FAQs />
        <Awards noClick={1} />
        <div ref={scrollRef}>
          <ContactUs />
        </div>
      </main>
    </div>
  );
}
