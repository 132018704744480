import { Fragment, useRef, useEffect, useState } from 'react';
import { MenuIcon, XIcon, ChevronDownIcon, ChevronRightIcon, ChevronUpIcon } from '@heroicons/react/outline';
import { Popover, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/img/logo.svg';
import healthcare from '../assets/img/technology/services-healthcare-color.svg';
import finance from '../assets/img/technology/services-finance-color.svg';
import education from '../assets/img/technology/services-education-color.svg';

// Import other necessary assets and components

const solutions = (currentLanguage) => [
  { name: 'our-work', href: `${currentLanguage === 'en' ? '' : '/es'}/our-work` },
  { name: 'about', href: `${currentLanguage === 'en' ? '' : '/es'}/about-us` },
  { name: 'careers', href: `${currentLanguage === 'en' ? '' : '/es'}/careers`, className: 'hidden xl:inline-block' },
  { name: 'Blog', href: `${currentLanguage === 'en' ? '' : '/es'}/blog`, className: 'hidden xl:inline-block' },
];

const subnavbar = (t, currentLanguage) => [
  {
    title: 'what-we-do',
    options: currentLanguage === 'en' 
      ? [
          { title: 'nearshore-service', icon: require('../assets/img/home/services-nearshore-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/nearshore-development` },
          { title: 'custom-web-develop', icon: require('../assets/img/home/services-web-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/custom-web-development` },
          { title: 'custom-mobile-develop', icon: require('../assets/img/home/services-mobile-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/custom-mobile-development` },
        ]
      : [
          { title: 'custom-web-develop', icon: require('../assets/img/home/services-web-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/custom-web-development` },
          { title: 'custom-mobile-develop', icon: require('../assets/img/home/services-mobile-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/custom-mobile-development` },
          { title: 'nearshore-service', icon: require('../assets/img/home/services-nearshore-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/nearshore-development` },
        ],
  },
  {
    title: 'services',
    options: [
      { title: 'cloud', icon: require('../assets/img/services/services-cloud-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/cloud-services` },
      { title: 'ai-integration', icon: require('../assets/img/services/services-ai-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/ai-integration-services` },
      { title: 'user-experience', icon: require('../assets/img/services/services-ux-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/ux-ui-services` },
      { title: 'analytics', icon: require('../assets/img/services/services-analytics-color.png'), url: `${currentLanguage === 'en' ? '' : '/es'}/services/analytics-services` },
    ],
  },
  {
    title: 'industries',
    options: [
      { title: 'health-care', icon: healthcare, url: `${currentLanguage === 'en' ? '' : '/es'}/industry/healthcare` },
      { title: 'finance', icon: finance, url: `${currentLanguage === 'en' ? '' : '/es'}/industry/finance` },
      { title: 'education', icon: education, url: `${currentLanguage === 'en' ? '' : '/es'}/industry/education` },
    ],
  },
];

const technologies = [
  { title: 'React', icon: require('../assets/img/services/tool-react.png'), url: '/technology/react-software-development' },
  { title: 'Python', icon: require('../assets/img/services/tool-python.png'), url: '/technology/python-software-development' },
  { title: 'AWS', icon: require('../assets/img/services/tool-aws.png'), url: '/technology/aws' },
  { title: 'Javascript', icon: require('../assets/img/services/tool-js.png'), url: '/technology/javascript-software-development' },
  { title: 'Ionic', icon: require('../assets/img/services/tool-ionic.png'), url: '/technology/ionic-software-development' },
];

const LanguageToggle = ({ changeLanguage, currentLanguage }) => (
  <div className="flex items-center justify-end lg:flex-1 lg:w-full">
    <button onClick={() => changeLanguage('en')} className={`whitespace-nowrap text-base font-bold hover:text-ablue-500 mr-2 ${currentLanguage === 'en' ? 'text-ablue-500' : 'text-agray-500'}`}>EN </button>
    <p className="whitespace-nowrap text-base font-bold text-agray-500"> | </p>
    <button onClick={() => changeLanguage('es')} className={`whitespace-nowrap text-base font-bold hover:text-ablue-500 ml-2 ${currentLanguage === 'es' ? 'text-ablue-500' : 'text-agray-500'}`}>ES </button>
  </div>
);

const MobileMenuButton = ({ onClick }) => (
  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-ablue-400 hover:text-ablue-500 hover:bg-agray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-ablue-500" onClick={onClick}>
    <span className="sr-only">Open menu</span>
    <MenuIcon className="h-6 w-6" aria-hidden="true" />
  </Popover.Button>
);

const NavbarContent = ({ t, currentLanguage, closeNav }) => (
  <Popover.Group as="nav" className="hidden lg:flex space-x-10">
    <ServicesDropdown t={t} currentLanguage={currentLanguage} closeNav={closeNav} />
    {solutions(currentLanguage).map((item) => (
      <Link 
        key={item.name} 
        to={item.href} 
        className={`text-base font-bold text-ablue-900 hover:text-ablue-500 focus:text-ablue-500 ${item.className || ''}`}
      >
        {t(item.name)}
      </Link>
    ))}
  </Popover.Group>
);

const ServicesDropdown = ({ t, currentLanguage, closeNav }) => {
  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className="group rounded-md inline-flex items-center text-base font-bold hover:text-ablue-500"
          >
            <span className="text-ablue-900 hover:text-ablue-500 focus:text-ablue-500">{t('services')}</span>
            {open ? (
              <ChevronUpIcon className="ml-2 h-5 w-5 text-ablue-600" aria-hidden="true" />
            ) : (
              <ChevronDownIcon className="ml-2 h-5 w-5 text-ablue-400" aria-hidden="true" />
            )}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-10 -ml-9 mt-3 transform w-screen max-w-md lg:max-w-4xl ml-11 lg:translate-x-1 ">
              <div className="rounded-3xl shadow-3xl ring-0 overflow-hidden bg-white ">
                <div className="relative grid bg-white pb-6 gap-4 px-8 grid-cols-3 mt-8">
                  {subnavbar(t, currentLanguage).map((item, index) => (
                    <SubnavbarSection key={index} item={item} closeNav={closeNav} t={t} closeDropdown={close} />
                  ))}
                  <TechnologiesSection t={t} closeNav={closeNav} closeDropdown={close} />
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

const SubnavbarSection = ({ item, closeNav, t, closeDropdown }) => (
  <div className="px-4 max-w-xl mx-auto h-full lg:max-w-none lg:mx-0 lg:px-0">
    <div className="h-full w-full">
      <div className="col-span-3">
        <h2 className="text-lg text-ablue-900 font-bold pb-6">{t(item.title)}</h2>
        {item.options.map((menu, index) => (
          <SubnavbarItem key={index} menu={menu} closeNav={closeNav} t={t} closeDropdown={closeDropdown} />
        ))}
      </div>
    </div>
  </div>
);

const SubnavbarItem = ({ menu, closeNav, t, closeDropdown }) => (
  <Link 
    to={menu.url} 
    onClick={() => {
      closeNav.current?.click();
      closeDropdown();
    }}
  >
    <div className="flex space-x-4 pb-8">
      <img className="h-10 w-[51px]" src={menu.icon} alt={t(menu.title)} />
      <h2 className={`text-[15px] py-3 my-auto font-serif ${window.location.pathname !== menu.url ? 'text-agray-500 hover:text-ablue-500' : 'text-ablue-500'}`}>
        {t(menu.title)}
      </h2>
    </div>
  </Link>
);

const TechnologiesSection = ({ t, closeNav, closeDropdown }) => (
  <div className="bg-agray-100 px-8 rounded-[30px] col-span-3">
    <h2 className="text-lg text-ablue-900 font-bold mt-8">{t('technologies')}</h2>
    <div className="grid grid-cols-5 mt-6">
      {technologies.map((tech, index) => (
        <Link 
          key={index} 
          to={tech.url} 
          onClick={() => {
            closeNav.current?.click();
            closeDropdown();
          }}
        >
          <div className="flex space-x-1 pb-8 col-span-1">
            <img className="h-10 w-[51px]" src={tech.icon} alt={tech.title} />
            <h2 className="text-[15px] text-agray-500 hover:text-ablue-500 my-auto font-serif">
              {tech.title}
            </h2>
          </div>
        </Link>
      ))}
    </div>
  </div>
);

const MobileMenu = ({ t, currentLanguage, closeNav, changeLanguage }) => {
  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (sectionTitle) => {
    setActiveSection(activeSection === sectionTitle ? null : sectionTitle);
  };

  return (
    <div className="fixed inset-0 z-50 bg-white bg-opacity-80 backdrop-blur-lg backdrop-filter overflow-hidden flex flex-col">
      {/* Header */}
      <div className="flex items-center justify-between px-6 py-4 border-b border-agray-200">
        <Link to={currentLanguage === 'en' ? '/' : '/es'}>
          <img className="h-8" src={logo} alt="Alluxi" />
        </Link>
        <Popover.Button className="text-agray-500 hover:text-ablue-500 focus:outline-none">
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </Popover.Button>
      </div>

      {/* Menu items */}
      <div className="flex-grow overflow-y-auto">
        <nav className="px-6 py-6 space-y-4">
          {subnavbar(t, currentLanguage).map((section) => (
            <div key={section.title} className="py-2">
              <button
                className="flex items-center justify-between w-full text-left text-xl font-bold text-ablue-900 focus:outline-none"
                onClick={() => handleSectionClick(section.title)}
              >
                {t(section.title)}
                <ChevronRightIcon
                  className={`h-6 w-6 transform transition-transform duration-200 ${
                    activeSection === section.title ? 'rotate-90' : ''
                  }`}
                />
              </button>
              {activeSection === section.title && (
                <ul className="mt-3 ml-4 space-y-3">
                  {section.options.map((item) => (
                    <li key={item.title}>
                      <Link
                        to={item.url}
                        className="flex items-center text-lg text-agray-500 hover:text-ablue-500"
                        onClick={() => closeNav()}
                      >
                        <img className="h-6 w-6 mr-3" src={item.icon} alt={t(item.title)} />
                        <span>{t(item.title)}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
          
          {/* Additional menu items */}
          {solutions(currentLanguage).map((item) => (
            <Link
              key={item.name}
              to={item.href}
              className="block text-xl font-bold text-ablue-900 hover:text-ablue-500"
              onClick={() => closeNav()}
            >
              {t(item.name)}
            </Link>
          ))}
        </nav>
      </div>

      {/* Footer */}
      <div className="px-6 py-4 border-t border-agray-200">
        <div className="flex justify-between items-center">
          <div className="flex space-x-4">
            <button onClick={() => changeLanguage('en')} className={`text-base font-bold hover:text-ablue-500 ${currentLanguage === 'en' ? 'text-ablue-500' : 'text-agray-500'}`}>EN</button>
            <span className="text-agray-500">|</span>
            <button onClick={() => changeLanguage('es')} className={`text-base font-bold hover:text-ablue-500 ${currentLanguage === 'es' ? 'text-ablue-500' : 'text-agray-500'}`}>ES</button>
          </div>
          <Link
            to={currentLanguage === 'en' ? '/contact' : '/es/contact'}
            className="px-6 py-2 text-sm font-medium text-white bg-ablue-600 rounded-full hover:bg-ablue-700 transition-colors duration-300"
            onClick={() => closeNav()}
          >
            {t('get-in-touch')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default function Navbar() {
  const { t, i18n } = useTranslation();
  const closeNav = useRef();
  const currentLanguage = i18n.language;
  const [isScrolled, setIsScrolled] = useState(false);

  const changeLanguage = (lng) => {
    const currentUrl = window.location.href;
    const updatedUrl = lng === 'es'
      ? currentUrl.includes('/es') ? currentUrl : currentUrl.replace(window.location.origin, `${window.location.origin}/es`)
      : currentUrl.replace('/es', '');
    window.history.replaceState({}, document.title, updatedUrl);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 80);
      const blogDetail = document.getElementById('blog-detail');
      if (blogDetail) {
        blogDetail.style.backgroundColor = window.scrollY > 80 ? 'white' : 'transparent';
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Call it once to set initial state
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Popover className={`fixed z-50 w-full transition-all duration-300 ${isScrolled ? ' shadow-md' : ''}`} id="id-barNav">
      {({ open, close }) => (
        <>
          <nav className={`fixed w-full z-20 top-0 left-0 transition-all duration-300 ${isScrolled ? 'backdrop-filter bg-white bg-opacity-50 backdrop-blur-lg py-2' : 'py-4'}`}>
            <div className={`max-w-screen-xl lg:grid lg:grid-cols-12 flex flex-wrap items-center justify-between mx-auto px-4 ${isScrolled ? 'lg:py-2' : 'lg:py-4'}`}>
              <Link to={currentLanguage === 'en' ? '/' : '/es'} className="col-span-2">
                <img src={logo} alt="Alluxi" className={`logo_alluxi transition-all duration-300 ${isScrolled ? 'w-28 h-10 lg:h-16 lg:w-36' : 'w-32 h-11 lg:h-[70px] lg:w-40'}`} />
              </Link>
              <div className="flex lg:order-2 col-span-3">
                <div className="hidden lg:flex items-center justify-end lg:flex-1 lg:w-full">
                  <LanguageToggle changeLanguage={changeLanguage} currentLanguage={currentLanguage} />
                </div>
                <Link to={currentLanguage === 'en' ? '/contact' : '/es/contact'} className="hidden lg:block ml-8 whitespace-nowrap inline-flex items-center justify-center px-[39px] py-[12px]  rounded-[28px] shadow-sm text-[14px] font-manrope text-white bg-gradient-to-r from-ablue-500 to-ablue-900 bg-animate-slow hover:from-agray-500 hover:to-agray-500">
                  <label>{t('get-in-touch')}</label>
                </Link>
                <div className="lg:hidden">
                  <MobileMenuButton onClick={() => {}} />
                </div>
              </div>
              <div className="ml-10 col-span-6 xl:col-span-7 flex flex-col items-center justify-center hidden w-full lg:flex lg:w-auto lg:order-1" id="navbar-sticky">
                <NavbarContent t={t} currentLanguage={currentLanguage} closeNav={closeNav} />
              </div>
            </div>
          </nav>

          {/* Horizontal bar */}
          <div className={`w-full h-px bg-agray-200 transition-all duration-300 ${isScrolled ? 'opacity-100' : 'opacity-0'}`}></div>

          {/* Mobile menu */}
          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Popover.Panel
              focus
              static
              className="fixed inset-0 z-50 lg:hidden"
            >
              <MobileMenu
                t={t}
                currentLanguage={currentLanguage}
                closeNav={close}
                changeLanguage={changeLanguage}
              />
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
