import { Link, useLocation } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/outline';
import ContactUs from './ContactUs';
import FAQs from './FAQs';
import { Helmet } from 'react-helmet';
import Ready from './Ready';
import WeHaveItAll2 from './WeHaveItAll2';
import WhyChooseUs from './WhyChooseUs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function ServicesMCommerceHero(props) {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const qParam = searchParams.get('q');

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  return (
    <>
      <Helmet>
        <title>{t('mcommerce')} | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t('mcommerce-desc')} />
        <meta property="og:description" content={t('mcommerce-desc')} />
        <meta property="og:image" content={require('../assets/img/services/hero-mcommerce.png')} />
        <meta property="og:title" content={t('mcommerce')} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <main className="bg-agray-100 pt-20 md:pt-40">
        <div className="px-4 md:px-0 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl md:grid md:grid-cols-2 pb-10 mx-auto">
          <div className="my-auto tv:px-0 md:order-2 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl tv:max-w-none tv:mx-[336px]">
            <img
              src={require('../assets/img/services/hero-mcommerce.png')}
              alt="hero_img"
              className="w-full h-auto max-w-md mx-auto rounded-lg" // Increased border radius for a more rounded look
            />
          </div>
          <div className="text-left w-full mt-8 md:mt-0">
            <div className="pb-4">
              <span className="text-sm tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                {t('mcommerce-title')}
              </span>
            </div>
            <h1 className="text-3xl md:text-5xl text-nblue-900 font-bold">
              {t('mcommerce-subtitle')}
            </h1>
            <div className="mt-9">
              <Link
                to={currentLanguage === 'en' ? '/contact' : '/es/contact'}
                className="hover:bg-nblue-600 bg-ablue-600 w-full md:w-72 flex items-center justify-center px-[22px] py-[17px] text-sm font-manrope rounded-[28px] text-white"
              >
                <label>{t('next-project')}</label>
              </Link>
            </div>
          </div>
        </div>
        <div className="bg-white pt-16 pb-16">
          <div className="px-4 md:px-8 lg:px-36 tv:px-[336px]">
            <div className="relative overflow-hidden pb-16 md:py-12">
              <div className="mt-12 md:mt-24 md:grid md:grid-cols-2 md:grid-flow-col-dense md:gap-6 items-center">
                <div className="md:order-2">
                  <div className="mt-6">
                    <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                      {t('mcommerce-desc-title')}
                    </span>
                    <h2 className="text-3xl md:text-5xl text-nblue-900 font-bold mt-2">
                      {t('mcommerce-desc-subtitle')}
                    </h2>
                    <p className="font-light mt-4 text-base md:text-lg text-agray-500">
                      {t('mcommerce-desc')}
                    </p>
                  </div>
                </div>
                <img
                  className="mt-12 md:mt-0 my-auto w-full h-auto md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px] rounded-lg object-cover" // Increased border radius for a more rounded look
                  src={require('../assets/img/services/mcommerce-desc.png')}
                  alt="mcommerce_desc"
                />
              </div>
            </div>
            <div className="relative pb-16 md:pb-[127px] overflow-hidden">
              <div className="md:grid md:grid-cols-2 md:grid-flow-col-dense gap-4 items-center">
                <div>
                  <div>
                    <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
                      {t('mcommerce-benefit-title')}
                    </span>
                    <h2 className="text-3xl md:text-5xl text-nblue-900 font-bold mt-2">
                      {t('mcommerce-benefit-subtitle')}
                    </h2>
                    <div className="mt-8 md:mt-12">
                      {['mcommerce-benefit1', 'mcommerce-benefit2', 'mcommerce-benefit3', 'mcommerce-benefit4', 'mcommerce-benefit5', 'mcommerce-benefit6'].map((benefit, index) => (
                        <div key={index} className="flex gap-[8px] mt-4 md:mt-6">
                          <CheckIcon className="mt-1 w-[22px] h-[22px] flex-shrink-0 bg-agray-500 text-white rounded-full" />
                          <p className="font-light text-base md:text-lg text-agray-500">
                            {t(benefit)}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <img
                  className="max-w-80 mx-auto my-8 mt-9 md:mt-0 w-full h-auto md:w-[300px] md:h-[300px] lg:w-[400px] lg:h-[400px] rounded-lg object-cover" // Increased border radius for a more rounded look
                  src={require('../assets/img/services/mcommerce-benefits.png')}
                  alt="mcommerce_benefits"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl tv:max-w-none tv:mx-[336px] mx-auto">
          <div className="pt-16 text-center">
            <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
              {t('tools')}
            </span>
            <h2 className="text-3xl md:text-5xl text-nblue-900 font-bold mt-2">
              {t('our-tech-stack')}
            </h2>
            <p className="font-light mt-4 text-base md:text-lg text-agray-500 w-full md:w-4/5 xl:w-1/2 mx-auto">
              {t('our-tech-desc')}
            </p>
          </div>

          <div className="py-10">
            <div className="mt-6 grid grid-cols-2 md:grid-cols-4 gap-8 pb-16 md:pb-[113px]">
              {['React', 'Flutter', 'Shopify', 'Magento'].map((tool, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div className="relative w-24 h-24 md:w-36 md:h-36 mb-2">
                    <img
                      className="w-full h-full object-contain rounded-full"
                      src={require(`../assets/img/services/tool-${tool.toLowerCase().replace(' ', '-')}.png`)}
                      alt={tool}
                    />
                    <div className="absolute inset-0 opacity-20 rounded-full"></div>
                  </div>
                  <p className="text-sm text-agray-500 text-center">{tool}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {qParam === 'landing' && <FAQs />}
        <WeHaveItAll2 />
      </main>
      {qParam === 'landing' && <WhyChooseUs />}
      {qParam === 'landing' ? <ContactUs /> : <Ready />}
    </>
  );
}
