import { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NotFound404() {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>404 | Alluxi</title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("page-not-found-desc")} />
        <meta property="og:title" content={t("page-not-found")} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={require("../assets/img/404.png")} />
        <meta property="og:description" content={t("page-not-found-desc")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="relative pt-44 m-auto bg-white ">
        <div className="m-auto top-0 w-11/12 xl:w-7/12 text-center">
          <p className="text-md pt-4 tracking-widest font-semibold text-ablue-600 font-bold">
            404
          </p>
          <h1 className="text-4xl xl:text-5xl text-nblue-900 font-bold">
            {t("page-not-found")}
          </h1>
          <p className="font-light mt-4  text-lg text-agray-500  ">
            {t("page-not-found-desc")}
          </p>
          <Link
            to="/"
            className="mt-10 w-44 m-auto flex items-center justify-center px-6 py-3  text-sm font-base rounded-3xl text-white bg-ablue-600 "
          >
            {t("take-me-home")}
          </Link>

          <img
            className="w-fit xl:h-full md:mt-9 md:h-80 md:ml-2"
            src={require("../assets/img/404.png")}
            alt="404 page - Page not found"
          />
        </div>
      </div>
    </Fragment>
  );
}
