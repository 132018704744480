import React from 'react';
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

const AccordionLayout = ({ title, children, index, activeIndex, setActiveIndex }) => {
    const handleSetIndex = (index) => (activeIndex !== index) && setActiveIndex(index);

    return (
        <div className="m-auto">
            <div onClick={() => handleSetIndex(index)}
                className="flex justify-between text-2xl py-6 text-left">
                <div className="font-bold text-ablue-900">{title}</div>
                <div className="flex items-center justify-center">
                    {
                        (activeIndex === index)
                            ? <ChevronUpIcon className="w-6 h-6 text-ablue-600"/>
                            : <ChevronDownIcon className="w-6 h-6 text-ablue-600"/>
                    }
                </div>
            </div>

            {(activeIndex === index) && (
                <div className="font-light text-justify text-lg text-agray-500 mb-8">
                    {children}
                </div>
            )}
        </div>
    );
};

export default AccordionLayout;