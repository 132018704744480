import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import AccordionLayout from "./AccordionLayout";

export default function FAQs() {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(1);

  return (
    <div className="relative pt-16 pb-16 m-auto bg-white">
      <div className="px-8 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto text-center">
        <span className="text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
          {t("FAQs")}
        </span>
        <h2 className="text-5xl text-nblue-900 font-bold mb-16">
          {t("faqs-title")}
        </h2>
        <AccordionLayout
          title={t("type-dev-offer")}
          index={1}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          {t("type-dev-offer-answer")}
        </AccordionLayout>
        <AccordionLayout
          title={t("how-long")}
          index={2}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          {t("how-long-answer")}
        </AccordionLayout>
        <AccordionLayout
          title={t("manage-communication")}
          index={3}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          {t("manage-communication-answer")}
        </AccordionLayout>
        <AccordionLayout
          title={t("past-example")}
          index={4}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          {t("past-example-answer")}
        </AccordionLayout>
        <AccordionLayout
          title={t("costs")}
          index={5}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          {t("costs-answer")}
        </AccordionLayout>
        <AccordionLayout
          title={t("testing")}
          index={6}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        >
          {t("testing-answer")}
        </AccordionLayout>
      </div>
    </div>
  );
}
