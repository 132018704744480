import { Fragment, useCallback, useRef, useEffect, useState } from "react";
import BlogSuggestions from "./BlogSuggestions";  // Add this line
import SocialShare from "./SocialShare";  // Add this import
import { Helmet } from "react-helmet";
import Loading from "../shared/Loading";
import Ready from "../Ready";
import dateFormat from "dateformat";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DOMPurify from 'dompurify';

export default function BlogDetail() {
  let { slug } = useParams();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [blogData, setBlogData] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const mainContentRef = useRef(null);
  const suggestionsRef = useRef(null);
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(true);

  const fetchBlogDetailData = useCallback(async () => {
    setLoading(true);
    fetch(
      `${process.env.REACT_APP_URL}/slug/${slug}?key=${process.env.REACT_APP_KEY}&include=tags,authors`
    )
      .then(async (response) => {
        const data = await response.json();
        const transformedBlog = data.posts.map((blogData) => {
          return {
            id: blogData.uuid,
            slug: blogData.slug,
            title: blogData.title,
            description: blogData.excerpt.replace(/\n/g, ""),
            image: blogData.feature_image,
            author: blogData.authors.map((authorData) => authorData.name),
            html: blogData.html,
            imageAlt: blogData.feature_image_alt || blogData.title, // Add alt text
          };
        });
        setBlogData(transformedBlog);
        setLoading(false);
      })
      .catch(() => {
        navigate("page-not-found");
      });
  }, [slug, navigate]);

  useEffect(() => {
    fetchBlogDetailData();
  }, [fetchBlogDetailData, location.pathname]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [slug]);

  useEffect(() => {
    const updateSuggestionsPosition = () => {
      if (mainContentRef.current && suggestionsRef.current) {
        const mainContentRect = mainContentRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const navbarHeight = 96; // Adjust this value based on your navbar's actual height
        const footerHeight = 100; // Adjust this value based on your footer's actual height
        const maxHeight = windowHeight - navbarHeight - footerHeight;

        if (mainContentRect.bottom > windowHeight - footerHeight) {
          suggestionsRef.current.style.position = 'fixed';
          suggestionsRef.current.style.top = `${navbarHeight}px`;
          suggestionsRef.current.style.maxHeight = `${maxHeight}px`;
          setIsSuggestionsVisible(true);
        } else {
          suggestionsRef.current.style.position = 'absolute';
          suggestionsRef.current.style.top = `${navbarHeight}px`;
          suggestionsRef.current.style.maxHeight = `${mainContentRect.height}px`;
          setIsSuggestionsVisible(false);
        }
      }
    };

    updateSuggestionsPosition();
    window.addEventListener('resize', updateSuggestionsPosition);
    window.addEventListener('scroll', updateSuggestionsPosition);
    return () => {
      window.removeEventListener('resize', updateSuggestionsPosition);
      window.removeEventListener('scroll', updateSuggestionsPosition);
    };
  }, [blogData]);

  const sanitizeAndAddClasses = (html) => {
    const sanitizedHtml = DOMPurify.sanitize(html);
    const parser = new DOMParser();
    const doc = parser.parseFromString(sanitizedHtml, 'text/html');

    // Add classes to headings
    doc.querySelectorAll('h1, h2, h3, h4, h5, h6').forEach(heading => {
      heading.classList.add('font-bold', 'text-nblue-900', 'mt-6', 'mb-4');
    });

    // Add specific classes based on heading level
    doc.querySelectorAll('h1').forEach(h1 => h1.classList.add('text-4xl'));
    doc.querySelectorAll('h2').forEach(h2 => h2.classList.add('text-3xl'));
    doc.querySelectorAll('h3').forEach(h3 => h3.classList.add('text-2xl'));
    doc.querySelectorAll('h4').forEach(h4 => h4.classList.add('text-xl'));

    // Add classes to paragraphs
    doc.querySelectorAll('p').forEach(p => {
      p.classList.add('mb-4', 'text-agray-500');
    });

    // Add classes to links
    doc.querySelectorAll('a').forEach(a => {
      a.classList.add('text-ablue-600', 'hover:underline');
    });

    // Update the list styling
    doc.querySelectorAll('ul, ol').forEach(list => {
      list.classList.add('ml-6', 'mb-4', 'list-disc', 'list-outside');
    });
    doc.querySelectorAll('li').forEach(li => {
      li.classList.add('mb-2', 'pl-1', 'text-agray-500');
    });

    // Center images and add margin
    doc.querySelectorAll('img').forEach(img => {
      img.classList.add('mx-auto', 'my-6', 'max-w-full', 'h-auto');
      // Wrap the image in a div for centering
      const wrapper = doc.createElement('div');
      wrapper.classList.add('flex', 'justify-center');
      img.parentNode.insertBefore(wrapper, img);
      wrapper.appendChild(img);
    });

    // Add styling to horizontal lines
    doc.querySelectorAll('hr').forEach(hr => {
      hr.classList.add('my-8', 'border-t', 'border-agray-300', 'opacity-80');
    });

    // Add styling to bold text
    doc.querySelectorAll('strong, b').forEach(boldText => {
      boldText.classList.add('text-nblue-900');
    });

    return doc.body.innerHTML;
  };

  return (
    <Fragment>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="relative pt-24 md:pt-32 min-h-screen bg-white transition-colors duration-300" id="blog-detail">
            <div className="flex flex-col max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div className="w-full lg:w-3/4 lg:pr-12" ref={mainContentRef}>
                {blogData.map((blog) => (
                  <div key={blog.id}>
                    <Helmet>
                      <title>{blog.title.slice(0, 60)}</title> {/* Limit title length */}
                      <meta name="description" content={blog.description.slice(0, 160)} /> {/* Limit description length */}
                      <meta property="og:title" content={blog.title.slice(0, 60)} />
                      <meta property="og:description" content={blog.description.slice(0, 160)} />
                      <meta property="og:image" content={blog.image} />
                      <meta property="og:url" content={window.location.href} />
                      <meta
                        property="og:url"
                        content={`https://www.alluxi.com/blog/detail/${slug}`}
                      />
                      <link rel="canonical" href={window.location.href} />
                    </Helmet>

                    <h1 className="text-5xl xl:text-6xl text-left text-nblue-900 font-bold mb-6">
                      {blog.title}
                    </h1>
                    <p className="text-md text-left pt-2 tracking-widest font-semibold text-ablue-600 uppercase">
                      {t("by")} {blog.author} |{" "}
                      {dateFormat(blog.date, "mmmm dd, yyyy")}
                    </p>
                    <div className="mt-8 mb-12 flex justify-center">
                      <img
                        src={
                          blog.image ?? require("../../assets/img/blog-default.png")
                        }
                        className="w-full h-auto max-h-[800px] object-contain rounded-xl"
                        alt={blog.imageAlt} // Use alt text
                      />
                    </div>
                    <div
                      className="blog_description content text-left text-lg"
                      dangerouslySetInnerHTML={{ __html: sanitizeAndAddClasses(blog.html) }}
                    />
                    
                    {/* Add SocialShare component here */}
                    <SocialShare url={window.location.href} title={blog.title} />
                  </div>
                ))}
                
                {/* Mobile Blog Suggestions */}
                <div className="mt-12 lg:hidden">
                  <h2 className="text-2xl font-bold text-nblue-900 mb-4">
                    {t("related-posts")}
                  </h2>
                  <BlogSuggestions vertical={true} />
                </div>
              </div>
              
              {/* Desktop Blog Suggestions */}
              <div className="hidden lg:block w-1/4 mt-8 lg:mt-0 lg:fixed lg:right-8 xl:right-[calc((100%-1280px)/2+2rem)] top-32 max-w-[300px]">
                <div 
                  className={`bg-white rounded-xl transition-opacity duration-300 ${
                    isSuggestionsVisible ? 'opacity-100' : 'opacity-0'
                  }`} 
                  ref={suggestionsRef}
                >
                  <h2 className="text-2xl font-bold text-nblue-900 mb-4 mt-8">
                    {t("related-posts")}
                  </h2>
                  <BlogSuggestions vertical={true} />
                  
                  {/* Add SocialShare component here for desktop */}
                  <SocialShare url={window.location.href} title={blogData[0]?.title} />
                </div>
              </div>
            </div>
          </div>
          <Ready />
        </>
      )}
    </Fragment>
  );
}
